import {useDispatch, useSelector} from 'react-redux';
import axios from "axios";
import { setGallery } from "../store/redusers/gallerySlice";

const useGallery = () => {
    const {gallery} = useSelector((state) => state.gallery);

    const dispatch = useDispatch();

    const fetchGallery = async () => {
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_BACKEND}/api/galleries?populate=*`);
            if (!data) return;
            dispatch(setGallery(data.data));
        } catch (error) {
            console.error('[it-girls]: get news failed', error);
        }
    }

    return {gallery, fetchGallery};
};

export default useGallery;
