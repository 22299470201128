import React, {useEffect, useRef} from "react";
import style from "./Advantages.module.scss";
import Title from "../../Components/Title";
import {Titles, AdvantagesText} from "../../constants";
import logo from "../../assets/Logo.svg";
import yellowStar from "../../assets/yellow-star.png";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

function Advantages() {
    const BlockOne = useRef(null);
    const BlockTwo = useRef(null);
    const BlockFree = useRef(null);
    const BlockFour = useRef(null);
    const Star = useRef(null);
    useEffect(() => {
        const el1 = BlockOne.current;
        const el2 = BlockTwo.current;
        const el3 = BlockFree.current;
        const el4 = BlockFour.current;
        const el5 = Star.current;

        gsap.fromTo(
            el1,
            {rotate: 0},
            {
                rotate: -2,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );
        gsap.fromTo(
            el2,
            {rotate: 0},
            {
                rotate: 4,
                scrollTrigger: {
                    trigger: el2,
                },
            }
        );
        gsap.fromTo(
            el3,
            {rotate: 0},
            {
                rotate: 2,
                scrollTrigger: {
                    trigger: el3,
                },
            }
        );
        gsap.fromTo(
            el4,
            {rotate: 0},
            {
                rotate: -1,
                scrollTrigger: {
                    trigger: el4,
                },
            }
        );
        gsap.fromTo(
            el5,
            {x: 200},
            {
                x: 0,
                scrollTrigger: {
                    trigger: el5,
                },
            }
        );
    }, []);

    const topCards = AdvantagesText[0].map((el) => {
        return (
            <div
                key={el.id}
                id={el.id}
                className={style.card}
                ref={el.block ? BlockOne : BlockTwo}
            >
                <p className={style["text-block"]}>{el.text}</p>
                <p className={style[`${el.classBg}`]}>{el.subtitle}</p>
            </div>
        );
    });
    const bottomCards = AdvantagesText[1].map((el) => {
        return (
            <div
                key={el.id}
                id={el.id}
                className={style.card}
                ref={el.block ? BlockFree : BlockFour}
            >
                <p className={style["text-block"]}>{el.text}</p>
                <p className={style[`${el.classBg}`]}>{el.subtitle}</p>
            </div>
        );
    });

    return (
        <div className={style["advantages-block"]} id="advantges">
            <img src={yellowStar} alt="yellow star" className={style["star-image"]} ref={Star}/>
            <Title>{Titles.TITLE_ADVANTAGES}</Title>
            <div className={style["container-cards"]}>
                <div className={style["advantages-cards"]}>{topCards}</div>
                <img src={logo} about="logo" className={style.logo} alt="logo"/>
                <div className={style["advantages-cards"]}>{bottomCards}</div>
            </div>
        </div>
    );
}

export default Advantages;
