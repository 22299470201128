import {createSlice} from '@reduxjs/toolkit';
import {cloneDeep} from 'lodash';

const initialState = {
    email:[],
    phone:[],
    name:[],
};

export const formSlice = createSlice({
    name: 'email',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = cloneDeep(action.payload);
        },
        setPhone: (state, action) => {
            state.email = cloneDeep(action.payload);
        },
        setName: (state, action) => {
            state.email = cloneDeep(action.payload);
        },

    }
});

export default formSlice.reducer;

export const {setEmail,setName,setPhone} = formSlice.actions;
