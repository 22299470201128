import React, {useRef, useEffect} from "react";
import style from "./Tasks.module.scss";
import Button from "../../Components/Button";
import Title from '../../Components/Title';
import { Titles, Task } from "../../constants";
import glass from '../../assets/glass.svg';
import letter from '../../assets/letter.png';
import greenPetals from '../../assets/green-petals.svg'
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

const tasks = Task.map((el)=>{
    return(
        <div key={el.id} id={`task-${el.id}`} className={style.card} >
            <img src={glass} alt='glass' className={style.glass}/>
            <p>{el.text}</p>
        </div>
    );
})

function Tasks() {
    const Petals = useRef(null);

    useEffect(() => {
        const el1 = Petals.current;
        const el2 = `#task-${Task[0].id}`;
        const el3 = `#task-${Task[1].id}`;
        const el4 = `#task-${Task[2].id}`;
        const el5 = `#task-${Task[3].id}`;
        const el6 = `#task-${Task[4].id}`;
        const el7 = `#task-${Task[5].id}`;
    
        gsap.fromTo(
            el1,
            {x: -200, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el1,
                },
            }
            
        );
        gsap.fromTo(
            el2,
            {y: 50, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el2,
                },
            }
            
        );
        gsap.fromTo(
            el3,
            {y: 50, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el3,
                },
            }
            
        );
        gsap.fromTo(
            el4,
            {y: 50, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el4,
                },
            }
            
        );
        gsap.fromTo(
            el5,
            {y: 50, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el5,
                },
            }
            
        );
        gsap.fromTo(
            el6,
            {y: 50, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el6,
                },
            }
            
        );
        gsap.fromTo(
            el7,
            {y: 50, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el7,
                },
            }
            
        );
    }, []);
  return (
        <div className={style["tasks-block"]}>
            <img src={greenPetals} alt='green petals' className={style['petals-image']} ref={Petals}/>
        <div className={style["content"]} >
        <Title>{Titles.TITLE_PROJECT_OBJECTIVES}</Title>
        <div className={style['cards-block']}>
            {tasks}
        </div>
        <Button link={true} black={true}/>
        </div>
        <img src={letter} alt='letter' className={style.letter}/>
    </div>
  );
}

export default Tasks;