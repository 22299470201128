import React, { useState } from "react";
import style from "./Program.module.scss";
import Button from "../../Components/Button";
import Title from "../../Components/Title";
import { ProgramItems } from "../../constants";
import row from "../../assets/arrow.svg";
import line from "../../assets/line.svg";
import pinkLine from "../../assets/pink-button-line.png";
import { Titles } from "../../constants";
import slesh from "../../assets/slesh.svg";

function Program() {
  const [blockStates, setBlockStates] = useState(
    Array(ProgramItems.length).fill(false)
  );

  const onToggleBlock = (index) => {
    setBlockStates((prevBlockStates) => {
      const updatedBlockStates = [...prevBlockStates];
      updatedBlockStates[index] = !updatedBlockStates[index];
      return updatedBlockStates;
    });
  };

  const items = ProgramItems.map((group) => {
    return group.map((el) => {
      const index = el.id - 1;

      return (
        <div key={el.id} className={style.contentBlock}>
          <div className={style["item-block"]}>
            <div className={style.item}>
              <img src={row} alt="row" />
              <p className={style["text-item"]}>
                <img
                  style={{ display: blockStates[index] ? "inline" : "none" }}
                  src={slesh}
                  alt="slesh"
                />
                {el.id}. {el.text}
              </p>
            </div>
            <img src={row} alt="row" style={{ transform: "rotate(180deg)" }} />
          </div>
          {blockStates[index] ? (
            <img src={pinkLine} alt="" style={{ width: "100%" }} />
          ) : (
            <img src={line} alt="" style={{ width: "100%" }} />
          )}
          <button
            className={style.buttonOpen}
            onClick={() => onToggleBlock(index)}
          >
            {blockStates[index] ? "Свернуть" : "Подробнее"}
          </button>
          <div
            className={style["scroll-block"]}
            style={
              blockStates[index] ? { display: "inline" } : { display: "none" }
            }
          >
            <ul>
              {el.menu.map((e, index) => {
                return <li key={index} className={style["items-component"]}>{e}</li>;
              })}
            </ul>
          </div>
        </div>
      );
    });
  });

  return (
    <div className={style["program-block"]} id="program">
      <div className={style["content"]}>
        <div>
          <Title>
            <p style={{ color: "white" }}>{Titles.TITLE_COURSE_PROGRAM}</p>
          </Title>
          <p className={style.subtitle}> (9 месяцев) </p>
        </div>
        <div className={style["program-content"]}>{items}</div>
        <Button link={true} black={false} />
      </div>
    </div>
  );
}

export default Program;
