import React, {useState, useRef, useEffect} from "react";
import style from "./Form.module.scss";
import Button from "../../Components/Button";
import Title from "../../Components/Title";
import {Titles, FORM_SUBTITLE, SuccessfulSendingText} from "../../constants";
import {setName, setEmail, setPhone} from "../../store/redusers/formSlice";
import pinkRow from "../../assets/arrow-pink.svg";
import QuestionMark from "../../assets/question-mark.svg";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SuccessfulSendingImage from "../../assets/successful-sending.png";
import emailjs from '@emailjs/browser';
import {
    setEmailInputError,
    setNameInputError,
    setPhoneInputError,
} from "../../store/redusers/formErrorsSlice";
import {useDispatch, useSelector} from "react-redux";
import {ERROR, SUCCESS} from "../../constants";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

function Form() {
    const [inputName, setInputName] = useState("");
    const [inputPhone, setInputPhone] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const {nameInputError, emailInputError, phoneInputError} = useSelector(
        (state) => state.formErrors
    );
    const dispatch = useDispatch();
    const form = useRef();
    const inputRef = useRef();

    const sendAMOCRMData = async () => {
        const data = {
            name: inputName,
            phone: inputPhone,
            email: inputEmail,
        };
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        };

        await fetch(`${window.location.origin}/amocrm/amo.php`, request)
            .catch((error) => {
                console.error('Error: ', error);
            });
    }

    const sendEmail = () => {
        return emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_PUBLIC_KEY
        );
    };

    const handleGetValue = () => {
        inputRef.current.value = `Имя: ${inputName}\nТелефон: ${inputPhone}\nEmail: ${inputEmail}`;
    };

    const handleName = (e) => {
        setInputName(e.target.value);
        if (e.target.value) {
            dispatch(setNameInputError(SUCCESS));
        } else {
            dispatch(setNameInputError(ERROR));
        }
    };

    const handleEmail = (e) => {
        if (
            /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(e.target.value)
        ) {
            setInputEmail(e.target.value);
            dispatch(setEmailInputError(SUCCESS));
        } else {
            dispatch(setEmailInputError(ERROR));
        }
    };

    const handlePhone = (e) => {
        setInputPhone(e.target.value);
        if (/^\d+$/.test(e.target.value)) {
            dispatch(setPhoneInputError(SUCCESS));
        } else {
            dispatch(setPhoneInputError(ERROR));
        }
    };

    const [isSuccess, setIsSuccess] = useState(false);
    const Question = useRef(null);
    const Row = useRef(null);

    useEffect(() => {
        const el1 = Question.current;
        const el2 = Row.current;

        gsap.fromTo(
            el1,
            {x: -200, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );
        gsap.fromTo(
            el2,
            {x: 200, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el2,
                },
            }
        );
    }, []);
    const sendUserInfo = async (event) => {
        event.preventDefault();
        if (!nameInputError) {
            dispatch(setNameInputError(ERROR));
        } else {
            dispatch(setName(inputName));
        }
        if (!emailInputError) {
            dispatch(setEmailInputError(ERROR));
        } else {
            dispatch(setEmail(inputEmail))
        }
        if (!phoneInputError) {
            dispatch(setPhoneInputError(ERROR));
        } else {
            dispatch(setPhone(inputPhone));
        }
        if (
            nameInputError === SUCCESS &&
            emailInputError === SUCCESS &&
            phoneInputError === SUCCESS
        ) {
            handleGetValue();
            await sendEmail();
            sendAMOCRMData();
            setIsSuccess(true);
        }

    };

    return (
        <div className={style["form-block"]} id="form">
            <img
                src={QuestionMark}
                alt="QuestionMark"
                className={style["question-image"]}
                ref={Question}
            />
            <img
                src={pinkRow}
                alt="pink row"
                className={style["arrow-image"]}
                ref={Row}
            />
            <div className={style.content}>
                <div>
                    <Title>
                        <p style={{color: "white"}}>{Titles.TITLE_SUBMIT_APPLICATION}</p>
                    </Title>
                    <p className={style["form-subtitle"]}>{FORM_SUBTITLE}</p>
                </div>
                {!isSuccess ? (
                    <form ref={form} className={style.content}>
                        <div className={style.form}>
                            <div className={style["input-group"]}>
                                <input
                                    placeholder="Имя"
                                    type="text"
                                    name="user_name"
                                    onChange={handleName}
                                    className={[
                                        style["form-input"],
                                        nameInputError === ERROR ? style["border-error"] : "",
                                        nameInputError === SUCCESS ? style["border-success"] : "",
                                    ].join(" ")}
                                    maxLength="255"
                                />
                                <input
                                    placeholder="Номер телефона"
                                    type="tel"
                                    onChange={handlePhone}
                                    className={[
                                        style["form-input"],
                                        phoneInputError === ERROR ? style["border-error"] : "",
                                        phoneInputError === SUCCESS ? style["border-success"] : "",
                                    ].join(" ")}
                                    maxLength="255"
                                />
                            </div>
                            <div className={style["group-email"]}>
                                <input
                                    placeholder="Адрес электронной почты"
                                    type="email"
                                    name="user_email"
                                    onChange={handleEmail}
                                    className={[
                                        style["form-input"],
                                        emailInputError === ERROR ? style["border-error"] : "",
                                        emailInputError === SUCCESS ? style["border-success"] : "",
                                    ].join(" ")}
                                    maxLength="255"
                                />
                                <textarea name="message" ref={inputRef} className={style.texterea}/>
                            </div>
                        </div>
                        <Button link={false} onClick={sendUserInfo} black={false}/>
                    </form>
                ) : (
                    <div className={style["successful-content"]}>
                        <p>{SuccessfulSendingText}</p>
                        <img src={SuccessfulSendingImage} alt="SuccessfulSendingImage"/>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Form;
