import {useDispatch, useSelector} from 'react-redux';
import axios from "axios";
import {setTeam} from "../store/redusers/teamSlice";

const useTeam = () => {
    const { team } = useSelector((state) => state.team);
    const dispatch = useDispatch();

    const fetchTeam = async () => {
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_BACKEND}/api/teams?populate=*`)
            if (!data) return;
            dispatch(setTeam(data.data));
        } catch (error) {
            console.error('[it-girls]: get team failed', error);
        }
    }

    return { team, fetchTeam };
};

export default useTeam;
