import React from "react";
import style from "./ChapterText.module.scss";

function ChaptersText({children}) {

  return (
    <p className={style.chaptersText}>
      {children}
    </p>
  );
}

export default ChaptersText;