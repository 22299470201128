import React from "react";
import MainPage from "./pages/MainPage";
import NewsPage from "./pages/NewsPage";
import NewsDetails from "./pages/NewsDetails";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TreatyPage from "./pages/TreatyPage";
import NotFoundPage from "./pages/NotFoundPage";
import "./App.css";

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/treaty" element={<TreatyPage />} />
          <Route path="/news/:id" element={<NewsDetails />} />
          <Route path='*' element={<NotFoundPage />}/>
        </Routes>
      </Router>
  );
}

export default App;
