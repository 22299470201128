import creativeProgremming from "./assets/programming.svg";
import creativeDesign from "./assets/design.svg";
import creativeCreation from "./assets/creation.svg";
import creativeLeadership from "./assets/leadership.svg";
import news1 from "./assets/news-image/news1.png";
import news2 from "./assets/news-image/news2.jpg";
import news3 from "./assets/news-image/news3.jpg";
import news4 from "./assets/news-image/news4.jpg";
import yellowHeart from "./assets/yellow-heart.svg";
import ZlataGlotova from "./assets/slider-photos/ZlataGlotova.png";
import VladVorob from "./assets/slider-photos/VladVorob.png";
import SovertkovaTanya from "./assets/slider-photos/SovertkovaTanya.png";
import EvgeniiSasim from "./assets/slider-photos/EvgeniiSasim.png";
import AnnaShaposhnikova from "./assets/slider-photos/AnnaShaposhnikova.png";
import VladimirGlotov from "./assets/slider-photos/VladimirGlotov.png";
import TaniaPasevina from "./assets/slider-photos/TaniaPasevina.png";
import ValeriaAbramovich from "./assets/slider-photos/ValeriaAbramovich.png";
import KristinaChechenec from "./assets/slider-photos/KristinaChechenec.jpeg";

export const ERROR = "error";
export const SUCCESS = "success";

export const HEADER_LINKS = [
    {
        id: 1,
        text: "О нас",
        link: "/#about",
    },
    {
        id: 2,
        text: "Направления обучения",
        link: "/#directions",
    },
    {
        id: 3,
        text: "Преимущества",
        link: "/#advantges",
    },
    {
        id: 4,
        text: "Программа курса",
        link: "/#program",
    },
    {
        id: 5,
        text: "Расписание",
        link: "/#schedule",
    },
    {
        id: 6,
        text: "Контакты",
        link: "/#contact",
    },
];

export const InformationBlockText = {
    TEXT_TITLE:
        "IT Girls - это новаторская IT-школа для девочек в возрасте от 7 до 15 лет.",
    TEXT_HARD_SKILLS:
        "Мы предлагаем комплексное обучение для девочек 7-15 лет, которое они смогут применить не только в сфере IT, но и в любой другой. ",
    TEXT_SOFT_SKILLS:
        "Ознакомление с языками программирования, основами дизайна дает представление о ведущих сферах, а сфера творчества, развития нового типа лидерства, работы в команде, проектной работе и поиска индивидуальных сильных сторон поможет на рынке будущего занять позиции сильного специалиста!",
    TEXT_YEAR:
        "Уже сейчас, для успешного построения карьеры, недостаточно развивать основные навыки для работы — hard skills. На первое место выходят soft skills: стрессоустойчивость, умение быстро принимать решения, креативность и критичность, а также умение работать в команде.",
    TEXT_IT_GERLS:
        "Понятие It-girls , пришедшее из фешн-среды, постепенно трансформировалось в понятие женщины сильной, независимой и успешной, которая занимается тем, чем мечтают заниматься другие. ",
    TEXT_MISSION: "Миссия IT-girls: ",
    TEXT_TARGET:
        "создать условия для развития круга творческих и успешных женщин в профессиях будущего. Мы даем возможность молодым девушкам стать уверенными в себе, творческими и квалифицированными лидерами в мире технологий.",
};

export const FounderText = [
    {
        id: 1,
        text: `Мы хотим вдохновлять девушек мечтать о большем: В It-Girls <span style="font-weight:700" > мы верим в силу мечты.</span>`,
    },
    {
        id: 2,
        text: ` <span style="font-weight:700" >Отдельное обучение</span> позволит создать психологически безопасное пространство для смелого выражения своих мыслей и идей, проявить лидерские качества и показать важность сестринской поддержки в сферах.`,
    },
    {
        id: 3,
        text: ` <span style="font-weight:700" >Передовые программы.</span> Программы тщательно разработаны, разделены на блоки по 4,5 месяца, которые можно проходить постепенно или включаться с отдельного блока.`,
    },
    {
        id: 4,
        text: ` <span style="font-weight:700" >Команда</span>. Наша команда профессионалов создает условия, направленные на достижение успеха каждой девочки.`,
    },
    {
        id: 5,
        text: ` <span style="font-weight:700" >Веселая и увлекательная среда обучения</span>. Мы создаем динамичную и интерактивную среду, способствующую творчеству и сотрудничеству.`,
    },
    {
        id: 6,
        text: `<span style="font-weight:700" >Укрепление доверия и лидерства</span>. Мы считаем, что уверенность - это ключ к успеху.`,
    },
    {
        id: 7,
        text: ` <span style="font-weight:700" >Нетворкинг и сообщество</span>. В It-Girls мы воспитываем сильное чувство общности и предоставляем возможности для налаживания контактов и наставничества.`,
    },
];
export const Titles = {
    TITLE_DIRECTIONS: "Направления обучения",
    TITLE_ADVANTAGES: "Преимущества",
    TITLE_PROJECT_OBJECTIVES: "Задачи проекта:",
    TITLE_COURSE_PROGRAM: "Программа курса:",
    TITLE_SCHEDULE: "Расписание",
    TITLE_OUR_TEAM: "Наша команда преподавателей:",
    TITLE_NEWS: "Новости",
    TITLE_GALLARY: "Галерея",
    TITLE_SUBMIT_APPLICATION: "Оставить заявку",
    TITLE_PARTNERS: "Партнеры",
    TITLE_CONTACT: "Контакты",
    TITLE_TREATLY: "Публичная оферта о заключении договора возмездного оказания услуг",
    TITLE_NEWS_DETAILS: "Больше новостей",
};

export const directions = [
    {
        id: 1,
        title: "Основы программирования",
        text: "Позволяют изучить базовые языки программирования, научиться строить сайты, постичь основы 3d-моделирования и анимации",
        img: creativeProgremming,
        class: "pink",
        ref: "PinkBlock",
    },
    {
        id: 2,
        title: "Основы дизайна",
        text: "Блок, позволяющий изучить законы построения картинки, основы композиции, фотографии, It-дизайна",
        img: creativeDesign,
        class: "yellow",
        ref: "YellowBlock",
    },
    {
        id: 3,
        title: "Творческий блок",
        text: "Блок, включающий изучение индивидуального творчества ребенка, развивающий мастерство самопрезентации, построения речи, умения держать себя перед большой аудиторией",
        img: creativeCreation,
        class: "green",
        ref: "GreenBlock",
    },
    {
        id: 4,
        title: "Блок лидерства и работы с командой",
        text: "Развивает навыки проектной работы и распределения ролей в команде в соответствии с сильными сторонами участников, закладывает основы успешной коммуникации, лидерских качеств, а также основы этикета, в том числе Digital",
        img: creativeLeadership,
        class: "blue",
        ref: "BlueBlock",
    },
];

export const AdvantagesText = [
    [
        {
            id: 1,
            text: "В команде работают профессионалы в разных областях, занятия проходят в живом формате, который позволяет создать интерес и погрузиться в изучаемую область.",
            subtitle: "ПРОФЕССИОНАЛЬНО",
            classBg: "pink",
            block: true,
        },
        {
            id: 2,
            text: "Ребенок будет готов не только выбрать будущую профессию, но и применить навыки ежедневно в общении с коллегами, друзьями, учебе в школе, а также создать собственное портфолио.",
            subtitle: "РЕЗУЛЬТАТИВНО",
            classBg: "black",
            block: false,
        },
    ],
    [
        {
            id: 3,
            text: "Возможность найти друзей, получить поддержку женского круга, а в дальнейшем создать женское комьюнити оффлайн встреч в удобном помещении, где можно не только работать с гаджетами, но и создавать невероятные творческие проекты.",
            subtitle: "ПОДДЕРЖИВАЮЩАЯ СРЕДА ",
            classBg: "black",
            block: true,
        },

        {
            id: 4,
            text: "Гибкий график встреч, а также возможность продолжить обучение блоками.",
            subtitle: "УДОБНО",
            classBg: "pink",
            block: false,
        },
    ],
];
export const Task = [
    {
        id: 1,
        text: "Сформировать у девочек активную творческую жизненную позицию",
    },
    {
        id: 2,
        text: "Создать поддерживающую творческую среду",
    },
    {
        id: 3,
        text: "Сформировать женское комьюнити в IT-cфере, а также в смежных сферах",
    },
    {
        id: 4,
        text: "Сформировать условия для самореализации, раскрытия потенциала и востребованности в будущем",
    },
    {
        id: 5,
        text: "Создать социально значимые проекты с возможной реализацией в будущем",
    },
    {
        id: 6,
        text: "Сформировать кадровый резерв",
    },
];

export const ProgramItems = [
    [
        {
            id: 1,
            text: "Основы информационных технологий",
            menu: [
                "Основы информационных технологий и их применение в повседневной жизни",
                "Компьютерная архитектура и работа компьютерных систем",
                "Роль операционных систем и программного обеспечения",
                "Основы сетевых технологий и интернета",
                "Безопасность в сети и основы кибербезопасности",
            ],
        },
        {
            id: 2,
            text: "Основы веб-дизайна",
            menu: [
                "Основы композиции",
                "Основы построения кадра",
                "Скетчинг",
                "Растровая графика",
                "Векторная графика",
                "Академический рисунок",
                "Анимирование ",
            ],
        },
        {
            id: 3,
            text: "Основы стиля",
            menu: [
                "Стиль и вдохновение. Отличие стиля от моды. Иконы стиля и правильное подражание. Классификация стилей",
                "Колористика и комбинирование цветов ",
                "Подбор принта и текстуры к лицу. Типажи внешности. Теория цветотипов и «цветоведьмы». И почему иногда она не работает",
                "Типы фигуры и подбор одежды",
                "Типажи по характеру и настроению как основа индивидуального стиля",
            ],
        },
    ],
    [
        {
            id: 4,
            text: "Творческих блок",
            menu: [
                "Сценическое движение",
                "Сценическая речь",
                "Мастерство актера и самопрезентация",
                "Работа на камеру, умение привлекать и удерживать внимание",
                "Сторителлинг и основы импровизации",
                "Постановка речи",
            ],
        },
        {
            id: 5,
            text: "Блок лидерства и работы в команде",
            menu: [
                `Освоение методики "Проектная деятельность"`,
                "Использование scrum-метода в разработке этапов и путей реализации проекта",
                "Изучение и анализ материалов и примеров лидерства, создание презентаций, интервью, квизов",
                "Распределение ролей в команде и создание проекта в команде с помощью аудио, визуальных и других методов",
                "Создание индидуального дизайна презентации проекта и командное представление результата",
            ],
        },
        {
            id: 6,
            text: "Основы этикета",
            menu: [
                "Digital-этикет",
                "Элегантность манер",
                "Столовый этикет",
                "Светский этикет",
                "Деловой и корпоративный этикет",
                "Международный этикет",
                "Основы персонального брендинга",
            ],
        },
    ],
];

export const ScheduleItems = [
    {
        id: 1,
        day: "ПН-ЧТ",
        time: "17:00 - 20:00",
    },
    {
        id: 2,
        day: "СБ",
        time: "10:00 - 18:00",
    },
    {
        id: 3,
        day: "ВС",
        time: "10:00 - 18:00",
    },
];

export const NewsCard = [
    {
        id: "1",
        img: news1,
        text: "ПЕРВЫЙ НАБОР IT-GIRLS В СЕНТЯБРЕ 2023 ГОДА!",
        day: "Июль 7, 2023",
        icon: yellowHeart,
        description:
            "Друзья, рады сообщить вам о первом наборе it-girls в сентябре 2023 года! В конце августа планируется серия открытых встреч. Следите за новостями.",
    },
    {
        id: "2",
        img: news2,
        text: "МАСТЕР-КЛАСС ДЛЯ ДЕВОЧЕК И ИХ РОДИТЕЛЕЙ.",
        day: "Август 25, 2023",
        icon: yellowHeart,
        description:
            "Школа комплексного развития для девочек 7-15 лет в IT сфере. Мы очень хотим познакомить вас с нашими преподавателями и системой обучения, именно поэтому приглашаем вас и ваших девочек на уникальное и бесплатное мероприятие, посвященное теме: Digital этикет! 📱💻 На данном мастер-классе вы сможете ближе познакомится с преподавателем по этикету Татьяной Совертковой - 💃🏻артистка балета, чайный мастер и ведущая чайных церемоний, специалист по выстраиванию дефиле, преподаватель этикета. 👩🏻‍🏫Татьяна проведет интерактивное занятие, которое поможет детям разобраться в основных правилах поведения в цифровом пространстве. Участие в мастер-классе бесплатное. Важно предварительно зарегистрироваться по телефону +375 29 363 89 98. Успей зарегистрироваться так как количество мест ограничено. 📆 Дата: 25 августа ⏰ Время: 19.00 📍 Место: Володько 9. Не упустите возможность помочь вашей дочке стать этичной и уверенной в сети! 🌟",
    },
    {
        id: "3",
        img: news3,
        text: "МАСТЕР-КЛАСС ДЛЯ ДЕВОЧЕК И ИХ РОДИТЕЛЕЙ.",
        day: "Август 27, 2023",
        icon: yellowHeart,
        description:
            "Школа комплексного развития для девочек 7-15 лет в IT сфере. Мы очень хотим познакомить вас с нашими преподавателями и системой обучения, именно поэтому приглашаем вас и ваших девочек на уникальное и бесплатное мероприятие, посвященное теме: кибербезопасность и основы Веб-разработки ! 📱💻 Данный мастер- класс проведет наш преподаватель по программированию Евгений Сасим. Сильные стороны Евгения Front-end веб-разработчик в NorthItGroup. Ключевые навыки: JavaScript / TypeScript / PHP. Фреймворки React, Angular. 👩🏻‍🏫Евгений расскажет о правилах безопасного поведения в сети и основные понятия программирования. Участие в мастер-классе бесплатное. Важно предварительно зарегистрироваться по телефону +375 29 363 89 98. Успей зарегистрироваться так как количество мест ограничено. 📆 Дата: 27 августа ⏰ Время: 12.00 📍 Место: Володько 9.",
    },
    {
        id: "4",
        img: news4,
        text: "МАСТЕР-КЛАСС ДЛЯ ДЕВОЧЕК И ИХ РОДИТЕЛЕЙ.",
        day: "Август 27, 2023",
        icon: yellowHeart,
        description:
            "Школа комплексного развития для девочек 7-15 лет в IT сфере. Мы очень хотим познакомить вас с нашими преподавателями и системой обучения, именно поэтому приглашаем вас и ваших девочек на уникальное и бесплатное мероприятие, посвященное теме: искусство самопрезентации «компас моей личности» ! 📱💻 Данный мастер- класс проведет наш преподаватель Анна Шапошникова - куратор, артист и режиссер бэби-театра \"Бусы\", социальный педагог, студентка лаборатории \"Бэби-лаб\" (Центр им.Вс.Мейерхольда, Москва). @_busy_studio 👩🏻‍🏫Анна поможет девочкам примерить разные роли, понять более близкий для них язык самовыражения. Участие в мастер-классе бесплатное. Важно предварительно зарегистрироваться по телефону +375 29 363 89 98. Успей зарегистрироваться так как количество мест ограничено. 📆 Дата: 27 августа ⏰ Время: 13.00 📍 Место: Володько 9.",
    }
];

export const ContactItems = [
    {
        id: 1,
        number1: "+375 29 565 54 33",
        number2: "+375 29 647 99 99",
        number3: "+375 29 363 89 98",
        link: "info@it-girls.by",
    },
];

export const Team = [
    {
        id: 1,
        image: ZlataGlotova,
        name: "Злата Глотова",
        description: "Педагог, куратор, артист и режиссер, сценарист бэби-театра \"Бусы\", студентка лаборатории \"Baby-lab\" (Центр им.Вс.Мейерхольда, Москва), преподаватель блока творчества.",
    },
    {
        id: 2,
        image: VladVorob,
        name: "Влад Воробьев",
        description: "Web-дизайнер, фотограф, любитель пленочной фотографии. Преподаватель блока web-дизайна.",
    },
    {
        id: 3,
        image: SovertkovaTanya,
        name: "Соверткова Татьяна",
        description: "Артистка балета, педагог.  Чайный мастер и ведущая чайных церемонии. Специалист по выстраиванию дефиле. Преподаватель этикета.",
    },
    {
        id: 4,
        image: EvgeniiSasim,
        name: "Евгений Сасим",
        description: "Front-end web developer в NorthItGroup. Ключевые навыки:  JavaScript / TypeScript / PHP. Фреймворки React, Angular.",
    },
    {
        id: 5,
        image: AnnaShaposhnikova,
        name: "Анна Шапошникова",
        description: "Куратор, артист и режиссер бэби-театра \"Бусы\", социальный педагог, студентка лаборатории \"Baby-lab\" (Центр им.Вс.Мейерхольда, Москва).",
    },
    {
        id: 6,
        image: VladimirGlotov,
        name: "Владимир Глотов",
        description: "Артист театра и кино, руководитель детской театральной студии, преподаватель блока творчества.",
    },
    {
        id: 7,
        image: TaniaPasevina,
        name: "Таня Пасевина",
        description: "Тренер, специалист по движению, сертифицированный коуч, менеджер.",
    },
    {
        id: 8,
        image: ValeriaAbramovich,
        name: "Валерия Абрамович",
        description: "Стилист, модель, профессиональный танцор, преподаватель основ стиля.",
    },
    {
        id: 9,
        image: KristinaChechenec,
        name: "Кристина Чеченец",
        description: "Практикующий дизайнер. Мастер по кастомизации одежды. Педагог с опытом работы 2 лет. Преподаватель веб-дизайна.",
    }
];

export const BUTTON_SUBMIT_APPLICATION = "Оставить заявку";
export const BUTTON_ERROR = "На главную";
export const FOOTER_TEXT = "Договор публичной оферты";
export const FORM_SUBTITLE =
    "Задайте интересующие вопросы,  уточните всю информацию и оставьте заявку";
export const SuccessfulSendingText = "Ваше заяка успешно отправлена";
