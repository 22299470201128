import {createSlice} from '@reduxjs/toolkit';
import {cloneDeep} from 'lodash';

const initialState = {
    team:[],
};

export const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setTeam: (state, action) => {
            state.team = cloneDeep(action.payload);
        }
    }
});

export default teamSlice.reducer;

export const {setTeam} = teamSlice.actions;
