import React from "react";
import style from "./Header.module.scss";
import HeaderMenu from "../../Components/HeaderMenu";
import logoItGirls from "../../assets/Logo.svg";
import BurgerMenu from "../../Components/BurgerMenu";

function Header() {
  return (
    <div className={style["header-block"]}>
      <a href="/#shool" className={style.link} >
      <img src={logoItGirls} alt="logo" />
      </a>
      <div className={style["header-tel"]}>
      <a  href='tel:+375 29 565 54 33'>+375 29 565 54 33</a>
      <a  href='tel:+375 29 647 99 99'>+375 29 647 99 99</a>
      <a  href='tel:+375 29 363 89 98'>+375 29 363 89 98</a>
      </div>
      <BurgerMenu/>
      <HeaderMenu />
    </div>
  );
}

export default Header;
