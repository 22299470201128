import React, {useEffect, useRef} from "react";
import {Titles} from "../../constants";
import {NavLink, useParams} from "react-router-dom";
import style from "./NewsDetails.module.scss";
import Header from "../../blocks/Header";
import Footer from "../../blocks/Footer";
import Title from "../../Components/Title";
import News from "../../blocks/News";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import pinkRow from '../../assets/arrow.svg';
import backTack from '../../assets/backtack.png';
import gsap from "gsap";
import useNews from "../../hooks/useNews";

gsap.registerPlugin(ScrollTrigger);

function NewsDetails() {

    const {id} = useParams();
    const {currentNews, fetchNews} = useNews(id);

    const Dots = useRef(null);
    const Line = useRef(null);

    useEffect(() => {
        const el1 = Dots.current;
        const el2 = Line.current;
        gsap.fromTo(
            el1,
            {rotate: 0},
            {
                rotate: 110,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );
        gsap.fromTo(
            el2,
            {scale: 0},
            {
                scale: 1,
                scrollTrigger: {
                    trigger: el2,
                },
            }
        );
    }, []);

    useEffect(() => {
        if (currentNews) return;
        fetchNews();
    }, []);

    return <>{currentNews &&
        <div key={currentNews.id} className={style["card-page"]} page='/' id='top'>
            <Header/>
            <div className={style["card"]}>
                <div className={style["card-main"]}>
                    <div className={style["card-container"]}>
                        <div className={style['flow-container']}>
                            <NavLink className={style['link-flow']} to={'/news'}>{Titles.TITLE_NEWS}</NavLink>
                            <img src={pinkRow} alt='pinkRow' style={{transform: 'rotate(180deg'}}/>
                            <p>{currentNews.attributes.title}</p>
                        </div>
                        <Title>{currentNews.attributes.title}</Title>
                        <p className={style["news-day"]}>{currentNews.attributes.date}</p>
                        <div className={style["card-photo"]}>
                            <img src={backTack} alt="" className={style.tackLeft}/>
                            <img src={backTack} alt="" className={style.tackRight}/>
                            <div className={style["content"]}>
                                <img
                                    src={`${process.env.REACT_APP_BACKEND}${currentNews.attributes.image.data.attributes.url}`}
                                    alt={currentNews.attributes.title}
                                    className={style["photo"]}
                                />
                                <p className={style["card-description"]}>{currentNews.attributes.description}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <News newsTitle={false} newClass={true}/>
            <Footer/>
        </div>
    }</>;
}

export default NewsDetails;