import {useDispatch, useSelector} from 'react-redux';
import axios from "axios";
import {setNews} from "../store/redusers/newsSlice";

const useNews = (id) => {
    const {news, currentNews} = useSelector((state) => ({
        news: state.news.news,
        currentNews: state.news.news.find((news) => news.id.toString() === id)
    }));

    const dispatch = useDispatch();

    const fetchNews = async () => {
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_BACKEND}/api/news?populate=*&sort[0]=id:desc`);
            if (!data) return;
            dispatch(setNews(data.data));
        } catch (error) {
            console.error('[it-girls]: get news failed', error);
        }
    }

    return {news, currentNews, fetchNews};
};

export default useNews;
