import React from "react";
import Header from "../../blocks/Header";
import Main from "../../blocks/Main";
import InformationBlock from "../../blocks/InformationBlock";
import TargetBlock from "../../blocks/TargetBlock";
import Directions from "../../blocks/Directions";
import Advantages from "../../blocks/Advantages";
import Tasks from "../../blocks/Tasks";
import Program from "../../blocks/Program";
import Schedule from "../../blocks/Schedule";
import Team from "../../blocks/Team";
import News from "../../blocks/News";
import Gallery from "../../blocks/Gallery";
import Form from "../../blocks/Form";
import Contact from "../../blocks/Contact";
import Partners from "../../blocks/Partners";
import Footer from "../../blocks/Footer";
import '../../App.css';


function MainPage() {
  return (
    <div className="App">
      <Header />
      <Main />
        <InformationBlock />
      <TargetBlock />
      <Directions />
      <div className="container">
        <Advantages />
      </div>
      <Tasks />
      <Program />
      <Schedule />
      <Team />
      <News newsTitle={true} />
      <Gallery />
      <Form/>
      <Partners/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default MainPage;