import React, { useState } from "react";
import style from "./HeaderMenu.module.scss";
import { HEADER_LINKS } from "../../constants";
import Button from "../Button";
import arrow from "../../assets/arrow.svg";

function HeaderMenu() {
  const [selectedId, setSelectedId] = useState(null);

  const item = HEADER_LINKS.map((el) => {
    return (
      <a
        href={el.link}
        key={el.id}
        className={style[el.id === selectedId ? "item-onclick" : "menu-item"]}
        onClick={() => setSelectedId(el.id)}
      >
        <img src={arrow} alt="arrow" />
        {el.text}
        <img src={arrow} alt="arrow" style={{ transform: "rotate(180deg)" }} />
      </a>
    );
  });

  return (
    <div className={style["menu-block"]}>
      {item}
      <Button link={true} black={true} />
    </div>
  );
}

export default HeaderMenu;
