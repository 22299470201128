import React, { useRef, useEffect } from "react";
import style from "./Contact.module.scss";
import Title from "../../Components/Title";
import { Titles, ContactItems } from "../../constants";
import girls from "../../assets/contact-girls.png";
import instagram from "../../assets/instagram.svg";
import tikTok from "../../assets/tik tok.svg";
import logo from "../../assets/Logo.svg";
import greenPetals from "../../assets/green-petals.svg";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import arrow from "../../assets/arrow.svg";

gsap.registerPlugin(ScrollTrigger);

const contactItems = ContactItems.map((el) => {
  return (
    <div key={el.id} className={style["contact-item"]}>
      <img src={logo} alt="logo" className={style["contact-logo"]} />
      <div className={style.rowsBlock}>
        <img src={arrow} alt="arrow" />
        <a href="tel:+375 29 565 54 33" className={style["contact-number"]}>
          {el.number1}
        </a>
        <img src={arrow} alt="arrow" style={{ transform: "rotate(180deg)" }} />
      </div>
      <div className={style.rowsBlock}>
        <img src={arrow} alt="arrow" />
        <a href="tel:+375 29 647 99 99" className={style["contact-number"]}>
          {el.number2}
        </a>
        <img src={arrow} alt="arrow" style={{ transform: "rotate(180deg)" }} />
      </div>
      <div className={style.rowsBlock}>
        <img src={arrow} alt="arrow" />
        <a href="tel:+375 29 363 89 98" className={style["contact-number"]}>
          {el.number3}
        </a>
        <img src={arrow} alt="arrow" style={{ transform: "rotate(180deg)" }} />
      </div>
      <div className={style.rowsBlock}>
        <img src={arrow} alt="arrow" />
        <a href="mailto:info@it-girls.by" className={style["contact-link"]}>
          {el.link}
        </a>
        <img src={arrow} alt="arrow" style={{ transform: "rotate(180deg)" }} />
      </div>
      <div className={style["contact-link-icon"]}>
        <a href="https://instagram.com/itgirls.by?igshid=MzRlODBiNWFlZA==">
          <img src={instagram} alt="instagram" />
        </a>
        <img src={tikTok} alt="tik tok" />
      </div>
    </div>
  );
});

function Contact() {
  const Petal = useRef(null);
  useEffect(() => {
    const el = Petal.current;

    gsap.fromTo(
      el,
      { x: 200, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: el,
        },
      }
    );
  }, []);
  return (
    <div className={style["contact-block"]} id="contact">
      <img
        src={greenPetals}
        about=";t='green petals"
        className={style["petals-image"]}
        ref={Petal}
      />
      <div className={style.content}>
        <Title>{Titles.TITLE_CONTACT}</Title>
        <div className={style["contact-items"]}>
          <div className={style.mapBlock}>
            <img src={girls} alt="map" />
          </div>
          <div>{contactItems}</div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
