import React, {useRef, useEffect} from "react";
import style from "./Directions.module.scss";
import Button from "../../Components/Button";
import Title from "../../Components/Title";
import { Titles, directions } from "../../constants";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

const items = directions.map((el)=>{
  return(
      // className={style['direction-card']}
      <div key={el.id} className={style[`${el.class}`]} id={`direction-${el.id}`}>  
          <img src={el.img} />
          <div className={style['container-text']}>
              <p className={style['card-title']}>{el.title}</p>
              <p className={style['card-text']}>{el.text}</p>
          </div>
      </div>
  );
})

function Directions() {
  useEffect(() => {
    const el1 = `#direction-${directions[0].id}`;
    const el2 = `#direction-${directions[1].id}`;
    const el3 = `#direction-${directions[2].id}`;
    const el4 = `#direction-${directions[3].id}`;

    gsap.fromTo(
        el1,
        {rotate: 0},
        {
          rotate: 1,
            scrollTrigger: {
                trigger: el1,
            },
        }
        
    );
    gsap.fromTo(
      el2,
      {rotate: 0},
      {
        rotate: -2,
          scrollTrigger: {
              trigger: el2,
          },
      }
  );
  gsap.fromTo(
    el3,
    {rotate: 0},
    {
        rotate:2,
        scrollTrigger: {
            trigger: el3,
        },
    }
);
gsap.fromTo(
  el4,
  {rotate: 0},
  {
      rotate: -3,
      scrollTrigger: {
          trigger: el4,
      },
  }
);
}, []);



  return (
    <div className={style["directions-block"]} id='directions'>
      <div className={style["content"]}>
      <Title>{Titles.TITLE_DIRECTIONS}</Title>
      <div className={style.cards} >
        {items}
      </div>
      <Button link={true} black={true} />
      </div>
    </div>
  );
}

export default Directions;
