import React, { useState } from "react";
import style from "./TreatyOne.module.scss";
import Chapters from "../Chapters";
import ChapterText from "../ChapterText";
import arrow from "../../../assets/arrow.svg";

function TreatyFive() {
  const [blockState, setBlockState] = useState(false);

  const onpressRow = () => {
    setBlockState(!blockState);
  };

  return (
    <div className={style["treaty"]}>
      <div className={style["treatly-swipe"]} onClick={onpressRow}> 
        <Chapters>5. Порядок сдачи-приемки оказанных услуг</Chapters>
        <button onClick={onpressRow} className={style["arrow-button"]}>
          <img src={arrow} alt="arrow" className={style.arrow} />
        </button>
      </div>
      <div
        className={style["scroll-block"]}
        style={blockState ? { display: "inline" } : { display: "none" }}
      >
        <div className={style["treaty-block-one"]}>
          <ChapterText>
            5.1. Факт оказания Услуги согласно по Договору подтверждается Актом,
            оформленным единолично Исполнителем в отношении всех Заказчиков на
            основании пункта 1 Постановления Министерства финансов Республики
            Беларусь от 21.12.2015 № 58 «О некоторых вопросах составления
            первичных учетных документов».
          </ChapterText>
          <ChapterText>
            5.2. Услуги считаются надлежаще оказанными с момента подписания Акта
            Исполнителем, в случае отсутствия претензий Заказчика к качеству
            оказанных услуг в течении 5 дней с момента проведениямероприятия.
          </ChapterText>
          <ChapterText>
            5.3. При наличии претензии к оказанным услугам, Заказчик должен
            направить Исполнителю письменный мотивированный отказ от приемки
            услуг по адресу, указанному в настоящем договоре, в срок не позднее
            5 календарных дней с момента окончания оказания услуг. В случае если
            услуги, предусмотренные настоящим Договором, не были предоставлены
            по вине Заказчика, обязательства Исполнителя считаются выполненными
            в полном объеме.
          </ChapterText>
          <ChapterText>
            5.4. При отсутствии претензий по окончании 5 календарных дней,
            услуги считаются оказанными Исполнителем и принятыми Заказчиком.
          </ChapterText>
          <ChapterText>
            5.5. При пропуске занятий без причины - денежные средства не
            возвращаются. Возможна отработка пропущенных занятий в другое время
            с другой группой по расписанию (согласовывается с Исполнителем
            отдельно).{" "}
          </ChapterText>
        </div>
      </div>
    </div>
  );
}

export default TreatyFive;
