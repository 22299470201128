import React, { useState } from "react";
import style from "./TreatyOne.module.scss";
import Chapters from "../Chapters";
import ChapterText from "../ChapterText";
import arrow from "../../../assets/arrow.svg";

function TreatyEleven() {
  const [blockState, setBlockState] = useState(false);

  const onpressRow = () => {
    setBlockState(!blockState);
  };

  return (
    <div className={style["treaty"]}>
      <div className={style["treatly-swipe"]} onClick={onpressRow}> 
        <Chapters>11. Реквизиты Исполнителя</Chapters>
        <button onClick={onpressRow} className={style["arrow-button"]}>
          <img src={arrow} alt="arrow" className={style.arrow} />
        </button>
      </div>
      <div
        className={style["scroll-block"]}
        style={blockState ? { display: "inline" } : { display: "none" }}
      >
        <div className={style["treaty-block-one"]}>
          <ChapterText>ООО "Язык бизнеса"</ChapterText>
          <ChapterText>
            BYN № BY42ALFA30122B07390010270000 в ЗАО «Альфа-Банк»,
          </ChapterText>
          <ChapterText>
            адрес банка: 220013, Минск, Ул. Сурганова, 43-47 BIC: ALFABY2X
          </ChapterText>
          <ChapterText>УНП: 193592613</ChapterText>
          <ChapterText>
            EUR №: BY27ALFA30122B07390020270000 в ЗАО «Альфа-Банк»,
          </ChapterText>
          <ChapterText>
            адрес банка: 220013, Минск, Ул. Сурганова, 43-47 BIC: ALFABY2X,
          </ChapterText>
          <ChapterText>
            Счет №400886919000EUR в COMMERZBANK AG, Франкфурт-на-Майне,
            Германия, SWIFT COBADEFF
          </ChapterText>
        </div>
      </div>
    </div>
  );
}

export default TreatyEleven;
