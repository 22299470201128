import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import style from "./BurgerMenu.style.module.scss";
import burgerButton from "../../assets/burger-button.svg";
import crossButton from "../../assets/cross-burger.svg";
import { HEADER_LINKS, ContactItems } from "../../constants";
import Button from "../Button";
import tikTok from "../../assets/tik tok.svg";
import instagram from "../../assets/instagram.svg";

const item = HEADER_LINKS.map((el) => {
  return (
    <Dropdown.Item bsPrefix={style["burger-item"]} href={el.link} key={el.id}>
      {el.text}
    </Dropdown.Item>
  );
});

const Contactitem = ContactItems.map((el) => {
  return (
    <div key={el.id} className={style["contact-block"]}>
      <div className={style["contact-items-text"]}>
        <p className={style["contact-items-adress"]}>{el.adress}</p>
        <a href="mailto:info@it-girls.by" className={style["contact-items-link"]}>{el.link}</a>
      </div>
      <div className={style["contact-link-icon"]}>
        <a href="https://instagram.com/itgirls.by?igshid=MzRlODBiNWFlZA==">
          <img src={instagram} alt="instagram" />
        </a>
        <img src={tikTok} alt="tik tok" />
      </div>
    </div>
  );
});

function BurgerMenu() {
  const [count, setCount] = useState(burgerButton);
  return (
    <Dropdown bsPrefix={style["burger-drop"]}>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        bsPrefix={style["toggle-drop"]}
      >
        <img
          src={count}
          alt=""
          onClick={() => {
            if (count === burgerButton) {
              setCount(crossButton);
            } else {
              setCount(burgerButton);
            }
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu
        bsPrefix={
          style[count === crossButton ? "dropdown-menuBurger" : "hide-dropdown"]
        }
        onClick={() => setCount(burgerButton)}
      >
        <div className={style["list-container"]}>
          <div className={style.list}>{item}</div>
          <Button black={true} />
          {Contactitem}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default BurgerMenu;
