import React, { useState } from "react";
import style from "./TreatyOne.module.scss";
import Chapters from "../Chapters";
import ChapterText from "../ChapterText";
import arrow from "../../../assets/arrow.svg";

function TreatyFour() {
  const [blockState, setBlockState] = useState(false);

  const onpressRow = () => {
    setBlockState(!blockState);
  };

  return (
    <div className={style["treaty"]}>
      <div className={style["treatly-swipe"]} onClick={onpressRow}> 
        <Chapters>4. Права и обязанности сторон</Chapters>
        <button onClick={onpressRow} className={style["arrow-button"]}>
          <img src={arrow} alt="arrow" className={style.arrow} />
        </button>
      </div>
      <div
        className={style["scroll-block"]}
        style={blockState ? { display: "inline" } : { display: "none" }}
      >
        <div className={style["treaty-block-one"]}>
          <ChapterText>4.1. Исполнитель обязуется:</ChapterText>
          <ChapterText>
            4.1.1. Оказать услуги в полном соответствии с предметом договора.
          </ChapterText>
          <ChapterText>
            4.1.2. После подтверждения факта, указанного в п. 3.3. настоящего
            договора, направить Заказчику электронным письмом на указанный им
            электронный адрес информацию о месте и времени проведения
            мероприятия.{" "}
          </ChapterText>
          <ChapterText>
            4.1.3. Не разглашать персональные данные Заказчика.
          </ChapterText>
          <ChapterText>
            4.1.4. Оказывать услуги по настоящему договору качественно и в срок,
            в соответствии с оговоренными условиями. Конкретные условия оказания
            услуг оговариваются Исполнителем посредством сайта Исполнителя или
            размещенных в общем доступе регламентационных документов, или в
            документах, ссылки на которые предоставляются при предоставлении
            доступа к материалам.
          </ChapterText>
          <ChapterText>4.2. Заказчик обязуется:</ChapterText>
          <ChapterText>
            4.2.1. Выполнять все условия, установленные настоящим договором,
            размещенные на сайте, содержащиеся в документах, доступ к которым
            предоставлен путем отправки ссылки на электронную почту Заказчика.
          </ChapterText>
          <ChapterText>
            4.2.2. Оплатить стоимость услуги в соответствие с настоящим
            договором, в том числе и отдельно установлено - внести все части,
            если оплата осуществляется частями.
          </ChapterText>
          <ChapterText>4.3. Исполнитель вправе:</ChapterText>
          <ChapterText>
            4.3.1. Привлекать третьих лиц для исполнения настоящего договора.
          </ChapterText>
          <ChapterText>
            4.3.2. Отказаться от исполнения обязательств по договору при условии
            полного возмещения Заказчику фактически понесенных убытков, не
            включая упущенную выгоду.
          </ChapterText>
          <ChapterText>
            4.3.3. Давать рекомендации, консультации и разъяснения по вопросам,
            касающимся оказываемых услуг. Подготовленные и предоставленные
            Заказчику (устно или письменно) рекомендации, консультации и
            разъяснения являются профессиональным мнением Исполнителя и носят
            рекомендательный характер.
          </ChapterText>
          <ChapterText>4.4. Заказчик имеет право:</ChapterText>
          <ChapterText>
            4.4.1. Контролировать оказание Исполнителем Услуг.
          </ChapterText>
          <ChapterText>
            4.4.2. Реализовывать свои права, установленные настоящим Договором.
          </ChapterText>
        </div>
      </div>
    </div>
  );
}

export default TreatyFour;
