import React from "react";
import style from "./Partners.module.scss";
import Title from "../../Components/Title";
import { Titles } from "../../constants";
import partnersImage from "../../assets/beads-partner.svg"
import heart from '../../assets/red-heart.svg';
import northIt from '../../assets/partner-northIt.svg';
import erhard from '../../assets/partner-erhard.svg'

function Partners() {
  return (
    <div className={style["partners-block"]}>
      <div className={style["partners-content"]}>
      <div className={style["colum1"]}>
        <Title>{Titles.TITLE_PARTNERS}</Title>
        <img src={heart} alt="heart" className={style.heart}/>
        </div>
        <div className={style["colum2"]}>
          <img src={erhard} className={style["partner"]} alt="img" />
          <img src={northIt} className={style["partner"]} alt="img" />
          <img src={partnersImage} className={style["partner"]} alt="img" />
        </div>
      </div>
    </div>
  );
}

export default Partners;
