import React, { useState } from "react";
import style from "./TreatyOne.module.scss";
import Chapters from "../Chapters";
import ChapterText from "../ChapterText";
import arrow from "../../../assets/arrow.svg";

function TreatyOne() {
  const [blockState, setBlockState] = useState(false);

  const onpressRow = () => {
    setBlockState(!blockState);
  };

  return (
    <div className={style["treaty"]}>
      <div className={style["treatly-swipe"]} onClick={onpressRow}> 
        <Chapters>1. Термины и определения</Chapters>
        <button onClick={onpressRow} className={style["arrow-button"]}>
          <img src={arrow} alt="arrow" className={style.arrow} />
        </button>
      </div>
      <div
        className={style["scroll-block"]}
        style={blockState ? { display: "inline" } : { display: "none" }}
      >
        <div className={style["treaty-block-one"]}>
          <ChapterText>
            1.1. Оферта – настоящий документ. Публикация (размещение) текста в
            сети Интернет на информационных ресурсах Исполнителя и (или) третьих
            лиц является публичным предложением, адресованным широкому кругу лиц
            с целью оказания определенных видов услуг.
          </ChapterText>
          <ChapterText>
            1.2. Акцепт оферты – полное и безоговорочное принятие оферты путем
            осуществления действий, указанных настоящем Договоре. Акцепт оферты
            создает Договор в соответствии со ст. 398 Гражданского Кодекса
            Республики Беларусь.
          </ChapterText>

          <ChapterText>
            1.3. Информационные ресурсы – Интернет-ресурсы, размещенные в сети
            Интернет, принадлежащие Исполнителю с целью возмездного оказания
            услуг по настоящему договору. К ним можно отнести Профили
            Исполнителя в социальных сетях, в которых с Заказчиком согласовано
            оказание различных услуг.
          </ChapterText>

          <ChapterText>
            1.4. Прайс - сайт Исполнителя, где размещена информация о стоимость
            оказываемых услуг исполнителя. Размещен на информационных ресурсах
            Исполнителя и публикуется в любой социальной сети.
          </ChapterText>

          <ChapterText>
            Акция – приобретение Заказчиком пакета, состоящего из нескольких
            Услуг (курсов) по стоимости ниже, чем суммарные затраты на
            приобретение отдельных, входящих в пакет услуг (курсов)
          </ChapterText>

          <ChapterText>
            1.5. Материалы – вебинары, онлайн-лекции, офлайн-мероприятия, аудио-
            и видеозаписи, разделенные на так называемые «пакеты» на усмотрение
            Исполнителя (включая темы и качество).
          </ChapterText>

          <ChapterText>
            1.6. Гайд - материалы графического и текстового содержания,
            объединенные одной тематикой. Являются результатом авторского труда
            Исполнителя. Рассрочка и постоплата при приобретении не применяются.
          </ChapterText>

          <ChapterText>
            1.7. Участник — физическое лицо, потребитель Услуг.
          </ChapterText>

          <ChapterText>
            1.8. Услуга — организация и проведение Исполнителем мероприятия.
            Услуги содержится в информации, размещенной на Сайте.
          </ChapterText>

          <ChapterText>
            1.9. Место оказание Услуги — согласно расписанию опубликованному на
            Сайте.
          </ChapterText>

          <ChapterText>
            1.10. Объявление — информация, размещенная на Сайте.
          </ChapterText>

          <ChapterText>
            1.11. Период предоставления - временной промежуток (может быть
            установлен в неделях, и/или днях, и/или единовременно), в течение
            которого поэтапно осуществляется оказание услуги.
          </ChapterText>

          <ChapterText>
            1.12. Рекламные материалы – любая информация рекламного характера
            (баннеры, гипертекстовые ссылки, пресс-релизы, статьи, заказные
            опросы, иные сервисы, выполняющие рекламные функции),
            предоставленная Заказчиком (или созданная в его интересах) в целях
            ее размещения на Информационных ресурсах Исполнителя и (или) третьих
            лиц.
          </ChapterText>

          <ChapterText>
            1.13. Обратная связь – услуга в форме устных или письменных ответов
            Исполнителя на вопросы.
          </ChapterText>

          <ChapterText>
            1.14. В настоящей Оферте могут быть использованы другие термины, не
            определенные в разделе «Термины и определения», в таком случае
            толкование используемой терминологии происходит в соответствии с
            текстом настоящей Оферты. В случае отсутствия однозначного
            толкования терминологии в тексте Оферты, следует руководствоваться
            толкованием терминов: в первую очередь – в материалах, размещенных
            на сайте Исполнителя itgirls.by (далее по тексту сайт); - во вторую
            очередь – толкованием и определениями терминов, сложившимся в сети
            интернет.
          </ChapterText>

          <ChapterText>
            1.15. В случае использования в Оферте терминов, не определенных в п.
            1.1-1.14 Оферты, толкование таких терминов производится в
            соответствии с текстом Оферты. В случае невозможности толкования
            терминов исходя из текста Оферты, термин подлежит толкованию исходя
            из следующего:
          </ChapterText>
        </div>
        <ul className={style["treaty-block-two"]}>
          <li>
            <ChapterText>в первую очередь – законодательства РБ;</ChapterText>
          </li>
          <li>
            <ChapterText>
              во вторую очередь – исходя из информации на Сайте Исполнителя;
            </ChapterText>
          </li>
          <li>
            <ChapterText>
              в третью очередь – сложившимся (общеупотребимым) толкованием
              данного термина.
            </ChapterText>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TreatyOne;
