import { combineReducers, configureStore } from "@reduxjs/toolkit";
import formSlice from "./redusers/formSlice";
import formErrorsSlice from "./redusers/formErrorsSlice";
import gallerySlice from "./redusers/gallerySlice";
import newsSlice from "./redusers/newsSlice";
import teamSlice from "./redusers/teamSlice";

const rootReducer = combineReducers({
  form: formSlice,
  formErrors: formErrorsSlice,
  news: newsSlice,
  team: teamSlice,
  gallery: gallerySlice,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};
