import React, {useEffect, useRef} from "react";
import style from './NewsPage.module.scss';
import Header from "../../blocks/Header";
import {Titles} from "../../constants";
import {Link} from "react-router-dom";
import row from '../../assets/arrow.svg';
import Title from '../../Components/Title';
import Footer from '../../blocks/Footer';
import blackStar from '../../assets/star.png';
import pinkStars from '../../assets/pink-stars.png';
import '../../App.css';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import useNews from "../../hooks/useNews";
import yellowHeart from "../../assets/yellow-heart.svg";

gsap.registerPlugin(ScrollTrigger);

function NewsPage() {

    const {fetchNews, news} = useNews();

    const Star = useRef(null);

    useEffect(() => {
        const el1 = Star.current;

        gsap.fromTo(
            el1,
            {x: -50, y: -50, opacity: 1, display: "inline"},
            {
                x: 100,
                y: 100,
                display: "none",
                opacity: 0,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );

    }, []);

    useEffect(() => {
        fetchNews();
    }, []);


    const item = news?.map((el) => {
        return (
            <Link key={el.id} to={`${el.id}`} style={{textDecoration: 'none'}}>
                <div className={style["card"]}>
                    <img src={yellowHeart} alt="icon" className={style["card-icon"]}/>
                    <img src={`${process.env.REACT_APP_BACKEND}${el.attributes.image.data.attributes.url}`}
                         alt={el.attributes.title} className={style["card-photo"]}/>
                    <p className={style["news-text"]}>{el.attributes.title}</p>
                    <p className={style["news-day"]}>{el.attributes.date}</p>
                    <div className={style["card-link"]}>
                        <p>Подробнее</p>
                        <img
                            src={row}
                            alt="row"
                            style={{transform: "rotate(180deg)", height: "24px"}}
                        />
                    </div>
                </div>
            </Link>
        );
    });

    return (
        <div className="App">
            <Header/>
            <img src={blackStar} alt='star' className={style['image-star']} ref={Star}/>
            <div className={style.content}>
                <div className={style['news-block']}>
                    <Title>{Titles.TITLE_NEWS}</Title>
                    <div className={style["cards-container"]}>{item}</div>
                    <img src={pinkStars} alt="icon" className={style["image-stars"]}/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default NewsPage;
