import React, { useRef, useEffect } from "react";
import style from "./Main.module.scss";
import Button from "../../Components/Button";
import messageImage from "../../assets/message.svg";
import bigStar from "../../assets/black-big-star.svg";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import littelYellowStar from "../../assets/littel-yellow-star.svg";
import BlackLine from "../../assets/black-line.svg";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

function Main() {
  const Text = useRef(null);
  const TagPink = useRef(null);
  const TagGreen = useRef(null);
  const TagYellow = useRef(null);
  const TagBlue = useRef(null);
  const ImageMessage = useRef(null);
  const ImageStar = useRef(null);
  const StarTop = useRef(null);
  const StarBottom = useRef(null);
  const Line = useRef(null);
  useEffect(() => {
    const el1 = Text.current;
    const el2 = TagPink.current;
    const el3 = TagYellow.current;
    const el4 = TagGreen.current;
    const el5 = TagBlue.current;
    const el6 = ImageMessage.current;
    const el7 = ImageStar.current;
    const el8 = StarTop.current;
    const el9 = StarBottom.current;
    const el10 = Line.current;

    gsap.fromTo(
      el1,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: el1,
        },
      }
    );
    gsap.fromTo(
      el2,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: el2,
        },
      }
    );
    gsap.fromTo(
      el3,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: el3,
        },
      }
    );
    gsap.fromTo(
      el4,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: el4,
        },
      }
    );
    gsap.fromTo(
      el5,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: el5,
        },
      }
    );
    gsap.fromTo(
      el6,
      { x: -200, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: el6,
        },
      }
    );
    gsap.fromTo(
      el7,
      { x: 200, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: el7,
        },
      }
    );
    gsap.fromTo(
      el8,
      { scale: 1.5 },
      {
        scale: 1,
        scrollTrigger: {
          trigger: el8,
        },
        duration: 1,
        repeat: -1,
        ease: "linear",
      }
    );
    gsap.fromTo(
      el9,
      { scale: 1 },
      {
        scale: 1.5,
        opacity: 1,
        scrollTrigger: {
          trigger: el9,
        },
        duration: 1,
        repeat: -1,
        ease: "linear",
      }
    );
    gsap.fromTo(
      el10,
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: el10,
        },
      }
    );
  }, []);
  return (
    <div className={style["main-block"]} id="shool">
      <a href="#directions" className={style["skills-pink"]} ref={TagPink}>
        Программирование
      </a>
      <a href="#directions" className={style["skills-yellow"]} ref={TagYellow}>
        Творчество
      </a>
      <img
        src={messageImage}
        alt="message image"
        className={style["message-image"]}
        ref={ImageMessage}
      />
      <img
        src={bigStar}
        alt="big star"
        className={style["star-image"]}
        ref={ImageStar}
      />
      <div className={style.content}>
        <h1 className={style["main-title"]} ref={Text}>
          IT-girls - комплексная школа навыков будущего для
          <br/>
          <span className={style["text-gitl"]}>
            <img
              src={littelYellowStar}
              alt="littel star"
              className={style["star-image-top"]}
              ref={StarTop}
            />
            девочек{" "}
            <img
              src={BlackLine}
              alt="black line"
              className={style["line-image"]}
              ref={Line}
            />
          </span>{" "}
          7-15 лет. Знакомство с миром технологий и раскрытие творческого потенциала
        </h1>
        <Button link={true} black={true} />
      </div>
      <a href="#directions" className={style["skills-green"]} ref={TagGreen}>
        Дизайн
      </a>
      <a href="#directions" className={style["skills-blue"]} ref={TagBlue}>
        Лидерство
      </a>
    </div>
  );
}

export default Main;
