import React, { useEffect, useRef } from "react";
import style from "./NotFoundPage.module.scss";
import Header from "../../blocks/Header";
import Footer from "../../blocks/Footer";
import sunError from "../../assets/sun-error.png";
import fourError from "../../assets/four-error.png";
import BlackArrow from "../../assets/black-arrow.png";
import creativeProgremming from "../../assets/programming.svg";
import creativeDesign from "../../assets/design.svg";
import creativeCreation from "../../assets/creation.svg";
import creativeLeadership from "../../assets/leadership.svg";
import "../../App.css";
import { NavLink } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

function NotFoundPage({ children }) {
  const ProgremmingImage = useRef(null);
  const DesignImage = useRef(null);
  const CreationImage = useRef(null);
  const LeadershipImage = useRef(null);
  const SunImage = useRef(null);
  useEffect(() => {
    const el1 = ProgremmingImage.current;
    const el2 = DesignImage.current;
    const el3 = CreationImage.current;
    const el4 = LeadershipImage.current;
    const el5 = SunImage.current;

    gsap.fromTo(
      el1,
      { scale: 0 },
      {
        scale: 1,
        scrollTrigger: {
          trigger: el1,
        },
      }
    );
    gsap.fromTo(
      el2,
      { scale: 0 },
      {
        scale: 1,
        scrollTrigger: {
          trigger: el2,
        },
      }
    );
    gsap.fromTo(
      el3,
      { scale: 0 },
      {
        scale: 1,
        scrollTrigger: {
          trigger: el3,
        },
      }
    );
    gsap.fromTo(
      el4,
      { scale: 0 },
      {
        scale: 1,
        scrollTrigger: {
          trigger: el4,
        },
      }
    );
    gsap.fromTo(
      el5,
      { rotate: 0 },
      {
        rotate: 360,
        scrollTrigger: {
          trigger: el5,
        },
        duration: 10,
        repeat: -1,
        ease: "linear",
      }
    );
  }, []);
    return (
      <div>
        <Header />
        <div className={style["notFound-page"]}>
          <img
            src={creativeProgremming}
            className={style.progremming}
            alt="Progremming"
            ref={ProgremmingImage}
          />
          <img
            src={creativeDesign}
            className={style.design}
            alt="Design"
            ref={DesignImage}
          />
          <div className={style.content}>
            <div className={style["error-images"]}>
              <img src={BlackArrow} alt="error" className={style['black-row']} />
              <div className={style["error-group"]}>
                <img src={fourError} alt="error" className={style['fout-error']} />
                <img src={sunError} alt="error" ref={SunImage} className={style['sun-error']} />
                <img src={fourError} alt="error" className={style['fout-error']} />
              </div>
              <img
                src={BlackArrow}
                alt="error"
                style={{ transform: "rotate(180deg) " }}
                className={style['black-row']}
              />
            </div>
            <p className={style["error-text"]}>Упс, страничка пропала.</p>
            <NavLink to="/">
              <button className={style.button}>На главную</button>
            </NavLink>
          </div>
          <img
            src={creativeCreation}
            className={style.creation}
            alt="Creation"
            ref={CreationImage}
          />
          <img
            src={creativeLeadership}
            className={style.leadership}
            alt="Leadership"
            ref={LeadershipImage}
          />
        </div>
        <Footer />
      </div>
    );
}

export default NotFoundPage;
