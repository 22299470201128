import React, { useState } from "react";
import style from "./TreatyOne.module.scss";
import Chapters from "../Chapters";
import ChapterText from "../ChapterText";
import arrow from "../../../assets/arrow.svg";

function TreatySix() {
  const [blockState, setBlockState] = useState(false);

  const onpressRow = () => {
    setBlockState(!blockState);
  };

  return (
    <div className={style["treaty"]}>
      <div className={style["treatly-swipe"]} onClick={onpressRow}> 
        <Chapters>6. Прочие условия</Chapters>
        <button onClick={onpressRow} className={style["arrow-button"]}>
          <img src={arrow} alt="arrow" className={style.arrow} />
        </button>
      </div>
      <div
        className={style["scroll-block"]}
        style={blockState ? { display: "inline" } : { display: "none" }}
      >
        <div className={style["treaty-block-one"]}>
          <ChapterText>
            6.1. Все результаты оказания услуг могут быть использованы
            Исполнителем в представительских целях (портфолио), если прямой
            запрет на данное действие не поступил от Заказчика.
          </ChapterText>
          <ChapterText>
            6.2. Услуги предоставляются только для личного использования
            Заказчиком. Запрещается передавать реквизиты доступа к материалам
            или сами материалы третьим лицам для их совместного использования
            без специального на то разрешения Исполнителя. Все содержимое
            материалов целиком или в любой его части является интеллектуальной
            собственностью Исполнителя и охраняется нормами внутреннего и
            международного законодательства.
          </ChapterText>
          <ChapterText>
            6.3. Вся информация о Заказчике, ставшая известной Исполнителю в
            процессе исполнения Договора, кроме данных, оговоренных в п. 6.4.
            настоящего Договора, считается конфиденциальной и не
            подлежитпередаче третьим лицам без разрешения Заказчика. Не
            считается нарушением данного пункта использование информации о
            получении услуг по настоящему Договору, в том числе выполнения
            заданий (при обсуждении, если это прямо не запрещено Заказчиком),
            ник-неймов в мессенджерах.
          </ChapterText>
          <ChapterText>
            6.4. Исполнитель не несет ответственности за отсутствие у Заказчика
            возможности воспользоваться доступом материалам по независящим от
            Исполнителя причинам (отсутствие у Заказчика технических
            возможностей доступа во время проведения вебинара или к его записи,
            использование Заказчиком ненадлежащего оборудования и канала в
            Интернет, сбои в работе оборудования провайдеров Интернет, ошибки со
            стороны Заказчика при регистрации и любые прочие). В этом случае
            Заказчик не вправе требовать возврата денежных средств или
            возмещения иных убытков со стороны Исполнителя.
          </ChapterText>
          <ChapterText>
            6.5. В случае невыполнения Заказчиком условий предоставления услуг,
            установленных настоящим договором, размещенных на сайте,
            содержащихся в документах, доступ к которым предоставлен путем
            отправки ссылки на электронную почту Заказчика (в частности -
            регламенты оказания услуг, регламенты обратной связи, иные
            документы), Исполнитель не несет ответственности за неполучение
            Заказчиком результата оказываемых услуг (под результатом понимается
            ожидаемый Заказчиком уровень усвоения материалов) или субъективно
            низкий процесс усвоения Заказчиком материалов, передаваемых в
            процессе оказания услуг.
          </ChapterText>
          <ChapterText>
            6.6. Исполнитель оставляет за собой право устанавливать критерии
            качества материалов, информационный состав, количество, тематика и
            наполнение рабочих часов и материалов устанавливаются Исполнителем
            на свое усмотрение.
          </ChapterText>
          <ChapterText>
            6.7. Переписка по электронной почте с адресов, указанных в разделе 8
            настоящего договора, а также с использованием мессенджеров по
            номерам/адресам, сообщенным в процессе заключения Договора, имеет
            силу простой электронной подписи и равнозначна бумажным документам с
            личными подписями (и, при их наличии, - печатями) сторон.
            Юридическая сила переданных таким образом документов, переписки
            является признанной Сторонами.
          </ChapterText>
          <ChapterText>
            6.8. Все споры, связанные с исполнением настоящего договора,
            решаются в претензионном порядке в течение 20 (двадцати) рабочих
            дней с даты получения претензии, а при недостижении согласия (или
            получении отказа в удовлетворения претензии) подлежат разрешению в
            Экономическом суде города Минска (Республика Беларусь) по месту
            регистрации Заказчика.
          </ChapterText>
          <ChapterText>
            6.9. Во всех остальных случаях стороны руководствуются действующим
            законодательством Республики Беларусь. В частности, к форс-мажорным
            обстоятельствам Стороны относят юридическую невозможность (задержку)
            исполнения (решения органов власти, банковских и небанковских
            органов и иное), также болезнь или жизненные обстоятельства Сторон,
            сопровождающиеся утратой (временной или полной) трудоспособности и
            другие форс-мажорные обстоятельства, перечисленные в пункте 8.1.,
            срок уведомления при наступлении форс-мажора – 5 банковских дней.
            Срок переноса обязательств в случае добросовестного уведомления – до
            1 месяца.
          </ChapterText>
        </div>
      </div>
    </div>
  );
}

export default TreatySix;
