import React, { useState } from "react";
import style from "./TreatyOne.module.scss";
import Chapters from "../Chapters";
import ChapterText from "../ChapterText";
import arrow from "../../../assets/arrow.svg";

function TreatyTree() {
  const [blockState, setBlockState] = useState(false);

  const onpressRow = () => {
    setBlockState(!blockState);
  };

  return (
    <div className={style["treaty"]}>
      <div className={style["treatly-swipe"]} onClick={onpressRow}> 
        <Chapters>3. Цена договора, порядок расчетов</Chapters>
        <button onClick={onpressRow} className={style["arrow-button"]}>
          <img src={arrow} alt="arrow" className={style.arrow} />
        </button>
      </div>
      <div
        className={style["scroll-block"]}
        style={blockState ? { display: "inline" } : { display: "none" }}
      >
        <div className={style["treaty-block-one"]}>
          <ChapterText>
            3.1. Стоимость услуг утверждается Исполнителем и составляет сумму в
            белорусских рублях, российских рублях и евро в размере, указанном на
            информационных ресурсах и (или) счете (интернет-ссылке) на оплату
            определенных Сторонами услуг, действующем на момент заключения
            Договора..{" "}
          </ChapterText>
          <ChapterText>
            3.2. Валютой договора является белорусский рубль (для резидентов
            Республики Беларусь) или евро по курсу НБ Республики Беларусь на
            дату зачисления на счет Исполнителя (для нерезидентов Республики
            Беларусь, при перечислении средств с территории иностранного
            государства). Оплата производится Заказчиком в соответствии с
            выбранным им пакетом участия. Данная стоимость является
            согласованной Сторонами. Комиссия, если она есть, при перечислении
            денежных средств уплачивается за счет Заказчика.
          </ChapterText>
          <ChapterText>
            3.3. Оплата производится в форме безналичных расчетов путем
            перечисления на текущий счет Исполнителя. Услуги оплачиваются в
            следующие сроки и в следующем порядке: 100% предоплата безналичным
            путем по банковским реквизитам Исполнителя. В случае, если услуга
            будет оказана до внесения предоплаты, то расчет за нее производится
            Заказчиком в течение 1 (одного) банковского дня со дня ее оказания.В
            случае невозможности исполнения, возникшей по вине Заказчика, в том
            числе из-за неявки в согласованное время, услуга подлежат оплате в
            полном объеме
          </ChapterText>
          <ChapterText>
            3.3.1. Заказчик может использовать для оплаты различные сервисы по
            приему и перечислению платежей, в случае, если Исполнитель будет
            использовать их в качестве средства приема платежей. О подключении
            сервисов по приему и перечислению платежей Заказчик будет уведомлен
            Исполнителем посредством сайта.
          </ChapterText>
          <ChapterText>
            3.4. В случае несвоевременной оплаты в установленный настоящим
            договором срок услуги не оказываются, в случае оплаты с опозданием -
            начало оказания услуг переносится на следующий период, анонсируемый
            на сайте (следующую группу слушателей).{" "}
          </ChapterText>
          <ChapterText>
            3.5. Обязательства Заказчика по оплате считаются исполненными на
            дату зачисления денежных средств на текущий счет Исполнителя.
          </ChapterText>
          <ChapterText>
            3.6. При оказании услуг связь с лицами, которые могут приобрести
            статус Заказчика, осуществляется через Информационные ресурсы и
            профиль Исполнителя.{" "}
          </ChapterText>
          <ChapterText>
            Профили используется в рекламных целях и с целью озвучивания
            промокодов и условий предпродаж.
          </ChapterText>
          <ChapterText>
            3.7. По усмотрению Исполнителя он может применять скидки при
            выполнении определенных условий, анонсированных через Информационные
            ресурсы, при приобретении лицом статуса Заказчика. Как правило (но
            не ограничиваясь) это условие об указании определенных промокодов,
            выполнении условий предпродаж, приобретение ранее услуг у
            Исполнителя.
          </ChapterText>
        </div>
      </div>
    </div>
  );
}

export default TreatyTree;
