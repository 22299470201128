import React from "react";
import style from "./Schedule.module.scss";
import { Titles, ScheduleItems } from "../../constants";
import Title from "../../Components/Title";
import hearts from '../../assets/hearts.svg';
import backtack from '../../assets/backtack.png';

const item = ScheduleItems.map((el) => {
  return (
    <div key={el.id} className={style['days-items']} >
      <p className={style['day-text']} >{el.day}</p>
      <p className={style['time-text']}>{el.time}</p>
    </div>
  );
});

function Schedule() {
  return (
    <div className={style["schedule-block"]} id='schedule' >
      <div className={style['card-schedule']} >
      <img src={backtack} alt='hearts' className={style.backtack} />
        <Title>{Titles.TITLE_SCHEDULE}</Title>
        {item}
        <img src={hearts} alt='hearts' className={style.hearts} />
      </div>
      
    </div>
  );
}

export default Schedule;
