import React, { useState } from "react";
import style from "./TreatyOne.module.scss";
import Chapters from "../Chapters";
import ChapterText from "../ChapterText";
import arrow from "../../../assets/arrow.svg";

function TreatyTwo() {
  const [blockState, setBlockState] = useState(false);

  const onpressRow = () => {
    setBlockState(!blockState);
  };

  return (
    <div className={style["treaty"]}>
      <div className={style["treatly-swipe"]} onClick={onpressRow}> 
        <Chapters>2. Предмет договора</Chapters>
        <button onClick={onpressRow} className={style["arrow-button"]}>
          <img src={arrow} alt="arrow" className={style.arrow} />
        </button>
      </div>
      <div
        className={style["scroll-block"]}
        style={blockState ? { display: "inline" } : { display: "none" }}
      >
        <div className={style["treaty-block-one"]}>
          <ChapterText>
            2.1. Предметом настоящей Оферты является оказание
            информационно-консультационных услуг в дистанционной и очной форме
            Участникам, включающей в себя активности по Программе, в порядке и
            на условиях, предусмотренных настоящей Офертой, — серии курсов,
            включающих в себя занятия, мероприятия (в том числе вечерние) и
            активности информационного, консультационного характера, в
            соответствии с Программой, Порядком оказания услуг, разработанными и
            утвержденными Исполнителем. Исполнитель обязуется предоставить
            Заказчику в порядке консультирования доступ к материалам и/или
            пакетам материалов, а Заказчик обязуется принять и оплатить
            стоимость услуг. В рамках оферты Исполнитель предоставляет Участнику
            доступ к различным активностям согласно Программе мероприятий.
          </ChapterText>
          <ChapterText>
            2.2. Дата начала (или условия начала) оказания услуг указывается
            (анонсируется) с помощью Информационных ресурсов Исполнителя.
          </ChapterText>
          <ChapterText>
            2.3. Услуги по настоящему договору ограничены предоставлением
            Заказчику информации, передачи знаний и опыта Исполнителя по
            вопросам работы по специальности и формированием навыков для их
            самостоятельного использования Заказчиком.
          </ChapterText>
          <ChapterText>
            Услуги по настоящему договору могут быть оказаны Заказчику в
            групповом формате, если это предусмотрено оплаченным
            пакетом/услугой.
          </ChapterText>
          <ChapterText>
            2.6. Услуги оказываются удаленно, а также в очном формате,
            посредствам сервисов, предусматривающих обмен информацией либо
            документов через сеть Интернет (Telegram, Viber, социальные сот и и
            т.п.). Формат оказания услуг содержит условия об объеме, содержании
            и форме оказании Услуг.
          </ChapterText>
        </div>
      </div>
    </div>
  );
}

export default TreatyTwo;
