import {createSlice} from '@reduxjs/toolkit';
import {cloneDeep} from 'lodash';

const initialState = {
    gallery:[]
};

export const gallerySlice = createSlice({
    name: 'gallery',
    initialState,
    reducers: {
        setGallery: (state, action) => {
            state.gallery = cloneDeep(action.payload);
        }
    }
});

export default gallerySlice.reducer;

export const {setGallery} = gallerySlice.actions;
