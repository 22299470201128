import React, { useEffect, useRef } from "react";
import style from "./TreatyPage.module.scss";
import Title from "../../Components/Title";
import { Titles } from "../../constants";
import Header from "../../blocks/Header";
import Footer from "../../blocks/Footer";
import TreatyOne from "./TreatyOne";
import TreatyTwo from "./TreatyTwo";
import TreatyTree from "./TreatyTree";
import TreatyFour from "./TreatyFour";
import TreatyFive from "./TreatyFive";
import TreatySix from "./TreatySix";
import TreatySeven from "./TreatySeven";
import TreatyEight from "./TreatyEight";
import TreatyNine from "./TreatyNine";
import TreatyTen from "./TreatyTen";
import TreatyEleven from "./TreatyEleven";
import blackDots from "../../assets/dots.png";
import redHeaet from "../../assets/red-heart.svg";
import "../../App.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import ChapterText from "./ChapterText";

gsap.registerPlugin(ScrollTrigger);

function TreatyPage() {
  const Dots = useRef(null);
  const Heart = useRef(null);

  useEffect(() => {
    const el1 = Dots.current;
    const el2 = Heart.current;

    gsap.fromTo(
      el1,
      { scale: 0 },
      {
        scale: 1,
        scrollTrigger: {
          trigger: el1,
        },
      }
    );
    gsap.fromTo(
      el2,
      { scale: 0 },
      {
        scale: 1,
        scrollTrigger: {
          trigger: el2,
        },
      }
    );
  }, []);

  return (
    <div>
      <Header />
      <div className={style.container}>
        <img
          src={blackDots}
          alt="dots"
          className={style["image-dots"]}
          ref={Dots}
        />
        <div className={style.contentContainer} >
        <Title>
          <p className={style.title} >{Titles.TITLE_TREATLY}</p></Title>
        <div className={style["trearty-block"]}>
          <div className={style.textTreaty}>
            <div className={style.data} ><ChapterText>«26» июня 2023 г. г. Минск</ChapterText></div>
            <ChapterText>Настоящий договор заключается между заключен между обществом с ограниченной ответственностью «Язык бизнеса», именуемым в дальнейшем «Подрядчик», в лице директора Хоффманна Штефана, действующего на основании Устава, именуемым в дальнейшем «Исполнитель», с одной стороны, и с любым лицом из числа неограниченного круга лиц, приобретающим статус Заказчика в соответствии с условиями настоящей оферты, именуемым в дальнейшем «Заказчик», с другой стороны, а вместе «Стороны», заключили настоящий договор о нижеследующем:</ChapterText>
            <ul>
              <li><ChapterText>Заполнения формы, с указанием персональных данных (имени, фамилии, номера телефона, адреса электронной почты или других).</ChapterText></li>
              <li><ChapterText>Заполнения формы, встроенной в Социальную сеть в рамках сообщества.</ChapterText></li>
              <li><ChapterText>Отправки email-сообщения на адреса электронной почты Сервиса.</ChapterText></li>
              <li><ChapterText> Телефонного вызова на номера телефонов.</ChapterText></li>
              <li><ChapterText>Отправки SMS-сообщения на номера телефонов.</ChapterText></li>
            </ul>
            <ChapterText>В случае акцепта настоящей публичной оферты, лицо, намеренное получить информацию на Сайте и/или получить доступ к платным или бесплатным услугам и/или совершить любые иные действия на Сайте, понимает и подтверждает, что:</ChapterText>
            <ul>
              <li><ChapterText>Текст публичной оферты понятен, каких-либо вопросов на момент акцепта публичной оферты у Пользователя услуг нет.</ChapterText></li>
              <li><ChapterText>Акцепт настоящей публичной оферты означает согласие на обработку персональных данных лица, намеренного получить услуги.</ChapterText></li>
              <li><ChapterText>Исполнитель вправе делать рассылку рекламно-информационного характера не только о своих услугах и предложениях, но и производить рассылку рекламно-информационного характера своих партнеров, то есть юридических лиц, индивидуальных предпринимателей, самозанятых граждан и физических лиц, сотрудничающих с Сервисом.</ChapterText></li>
            </ul>
            <ChapterText>Все вопросы, не урегулированные настоящей публичной офертой, решаются в соответствии с действующим законодательством Республики Беларусь.</ChapterText>
            <ChapterText>Под акцептом настоящей публичной оферты понимается совершение определенных действий, при которых Пользователь (Заказчик) в явном виде указывает свои персональные данные или такие данные передаются в автоматическом режиме от сторонних сервисов (Социальных сетей, Мессенджеров, Операторов связи и других), совершение оплаты.</ChapterText>
            <ChapterText>В случае акцепта настоящей публичной оферты, лицо, намеренное получить информацию на Сайте и/или получить доступ к платным или бесплатным услугам Сервиса и/или совершить любые иные действия на Сайте, понимает и подтверждает, что:</ChapterText>
            <ChapterText>Текст публичной оферты понятен, каких-либо вопросов на момент акцепта публичной оферты у Пользователя услуг нет.</ChapterText>
          </div>
          <TreatyOne />
          <TreatyTwo />
          <TreatyTree />
          <TreatyFour />
          <TreatyFive />
          <TreatySix />
          <TreatySeven />
          <TreatyEight />
          <TreatyNine />
          <TreatyTen />
          <TreatyEleven />
        </div>
        <img
          src={redHeaet}
          alt="dots"
          className={style["image-heart"]}
          ref={Heart}
        />
      </div>
      </div>
      <Footer />
    </div>
  );
}

export default TreatyPage;
