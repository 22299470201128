import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, EffectCoverflow} from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

export default function Slider({team}) {
    return (
        <>
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 35,
                    depth: 50,
                    modifier: 3,
                    slideShadows: false,
                    scale: 0.98,
                }}
                spaceBetween={0}
                navigation={true}
                modules={[Navigation, EffectCoverflow]}
                allowTouchMove={false}
                className="team-slider"
                loop={true}
            >
                {
                    team.concat(team).map((member, index) => {
                        const image = `${process.env.REACT_APP_BACKEND}${member.attributes.photo.data.attributes.url}`;
                        return <SwiperSlide key={`${member.id}_${index}`}>
                            <div className="image-wrapper">
                                {
                                    image &&
                                    <img
                                        src={`${process.env.REACT_APP_BACKEND}${member.attributes.photo.data.attributes.url}`}
                                        alt={member.attributes.name}/>
                                }
                            </div>
                            <div className="information">
                                <p className="title">{member.attributes.name}</p>
                                <p className="text">{member.attributes.description}</p>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </>
    );
}
