import React, {useEffect} from "react";
import style from "./News.module.scss";
import Title from "../../Components/Title";
import {Titles} from "../../constants";
import {NavLink, Link} from "react-router-dom";
import row from "../../assets/arrow.svg";
import useNews from "../../hooks/useNews";
import yellowHeart from "../../assets/yellow-heart.svg";

function News({newsTitle, newClass}) {

    const {fetchNews, news} = useNews();

    function scrollToTop() {
        const element = document.getElementById("top");
        if (element) {
            element.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }

    useEffect(() => {
        fetchNews();
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const item = news?.slice(0, 3).map((el) => {
        return (
            <Link key={el.id} to={`/news/${el.id}`} style={{textDecoration: "none"}} onClick={scrollToTop}>
                <div className={style["card"]}>
                    <img src={yellowHeart} alt="yellow-heart" className={style["card-icon"]}/>
                    <img src={`${process.env.REACT_APP_BACKEND}${el.attributes.image.data.attributes.url}`}
                         alt={el.attributes.title}
                         className={style["card-photo"]}/>
                    <p className={style["news-text"]}>{el.attributes.title}</p>
                    <p className={style["news-day"]}>{el.attributes.date}</p>
                    <div className={style["card-link"]}>
                        <p>Подробнее</p>
                        <img
                            src={row}
                            alt="row"
                            style={{transform: "rotate(180deg)", height: "24px"}}
                        />
                    </div>
                </div>
            </Link>
        );
    });
    return (
        <div className={style["news-block"]}>
            <div className={style["content"]}>
                <div className={style["content-head"]}>
                    <div
                        className={[
                            newClass ? style["title-details"] : style["title-head"]
                        ].join("")}
                    >
                        <Title>
                            {newsTitle ? Titles.TITLE_NEWS : Titles.TITLE_NEWS_DETAILS}
                        </Title>
                    </div>
                    <NavLink
                        to="/news"
                        style={{
                            textDecoration: "none",
                            display: newsTitle ? "inline" : "none",
                        }}
                    >
                        <div className={style["link"]}>
                            <p>Все новости</p>
                            <img
                                src={row}
                                alt="row"
                                style={{transform: "rotate(180deg)", height: "24px"}}
                            />
                        </div>
                    </NavLink>
                </div>
                <div className={style["cards-container"]}>{item}</div>
            </div>
        </div>
    );
}

export default News;
