import React, { memo, useEffect } from "react";
import useGallery from "../../hooks/useGallery";
import style from "./Gallery.module.scss";
import Title from "../../Components/Title";
import { Titles } from "../../constants";
import blackSticker from '../../assets/black-sticker.svg';
import pinkSticker from '../../assets/pink-sticker.svg';
import blueStars from '../../assets/blue-stars.svg';
import redHeart from '../../assets/red-heart.svg';

const randomNumber = 7;

const random = () => Math.round(Math.random() * randomNumber);
const rotate = () => (Math.random() > 0.5 ? random() : 360 - random());

const GalleryImage = memo(({ src }) => {
  return (
    <img
      src={src}
      className={style.image}
      alt="photo1"
      style={{
        top: `${random()}px`,
        left: `${random()}px`,
        rotate: `${rotate()}deg`,
      }}
    />
  );
});

const stickersIndexes = [1, 2, 7, 12];

function Gallery() {

  const {gallery, fetchGallery} = useGallery();

  useEffect(() => {
    fetchGallery();
  }, []);

  const getNecessarySticker = (index) => {
    switch (index) {
      case 1: {
        return {
          sticker: pinkSticker,
          classNameForSticker: style['pink-sticker'],
        };
      }
      case 2: {
        return {
          sticker: redHeart,
          classNameForSticker: style['heart'],
        };
      }
      case 7: {
        return {
          sticker: blueStars,
          classNameForSticker: style['blue-star'],
        };
      }
      case 12: {
        return {
          sticker: blackSticker,
          classNameForSticker: style['black-sticker'],
        };
      }
      default: {
        return {
          sticker: pinkSticker,
          classNameForSticker: style['pink-sticker'],
        };
      }
    }
  }

  const item = gallery?.map((el, index) => {
    return <div key={el.id} className={style["gallery-sticker"]}>
      {stickersIndexes.includes(index + 1) ? <img src={getNecessarySticker(index + 1)?.sticker} alt='' className={getNecessarySticker(index + 1)?.classNameForSticker}/> : ''}
      <GalleryImage src={`${process.env.REACT_APP_BACKEND}${el.attributes.gallery.data.attributes.url}`} />
    </div>
  });

  return <div className={style["gallery-block"]}>
      <Title>{Titles.TITLE_GALLARY}</Title>
      <div className={style["gallery-container"]}>
        {item}
      </div>

    </div>;
}

export default Gallery;

