import React, { useEffect, useRef } from "react";
import style from "./InformationBlock.module.scss";
import { InformationBlockText } from "../../constants";
import hearts from "../../assets/hearts.svg";
import photoGerl from "../../assets/photo-girl.png";
import star from "../../assets/star.png";
import dots from "../../assets/dots.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

function InformationBlock() {
  const ImageGirl = useRef(null);
  const ImageStar = useRef(null);
  const TargetText = useRef(null);

  useEffect(() => {
    const el1 = ImageGirl.current;
    const el2 = ImageStar.current;
    const el3 = TargetText.current;

    gsap.fromTo(
      el1,
      { rotate: 0, opacity: 0 },
      {
        rotate: 360,
        opacity: 1,
        scrollTrigger: {
          trigger: el1,
        },
      }
    );
    gsap.fromTo(
      el2,
      { x: 500, y: -50 },
      {
        x: 0,
        y: 0,
        scrollTrigger: {
          trigger: el2,
        },
      }
    );
    gsap.fromTo(
      el3,
      { scale: 0.5 },
      {
        scale: 1,
        scrollTrigger: {
          trigger: el3,
        },
      }
    );
  }, []);
  return (
    <div className={style["main-container"]}>
      <img src={dots} alt="dots" className={style.dots} />
      <div className={style["information-container"]}>
        <div className={style["information-block"]} id="about">
          <div className={style["blocks"]}>
            <div className={style["text-container"]}>
              <img
                src={star}
                alt="star"
                className={style.star}
                ref={ImageStar}
              />
              <p className={style.title}>{InformationBlockText.TEXT_TITLE}</p>
              <p className={style.text}>
                {InformationBlockText.TEXT_HARD_SKILLS}
              </p>
              <p className={style.text}>
              Ознакомление с языками программирования, основами дизайна дает представление о ведущих сферах, а сфера творчества, развития нового типа лидерства, работы в команде, проектной работе и поиска индивидуальных сильных сторон поможет на рынке будущего <span style={{fontWeight:700}} > занять позиции сильного специалиста</span>!
              </p>
            </div>
            <img
              src={photoGerl}
              alt="photo-gerl"
              ref={ImageGirl}
              className={style.imageGirl}
            />
          </div>
          <div className={style["blocks"]}>
            <img src={hearts} about="hearts" className={style.hearts} />
            <div className={style["text-container"]}>
              <p className={style.text}>Уже сейчас для успешного построения карьеры недостаточно развивать основные навыки для работы — <span style={{fontWeight:700}} >hard skills. На первое место выходят soft skills</span>: стрессоустойчивость, умение быстро принимать решения, креативность и критичность, а также умение работать в команде.</p>
              <p className={style.text}>Понятие It-girls , пришедшее из фешн-среды, постепенно трансформировалось в понятие <span style={{fontWeight:700}} > женщины сильной, независимой и успешной, которая занимается тем, чем мечтают заниматься другие.</span> </p>
            </div>
          </div>
        </div>
        <div className={style["target-block"]} ref={TargetText}>
          <p className={style["title-target"]}>
            {InformationBlockText.TEXT_MISSION}
          </p>
          <p className={style["text-target"]}>
            {InformationBlockText.TEXT_TARGET}
          </p>
        </div>
      </div>
    </div>
  );
}

export default InformationBlock;
