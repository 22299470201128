import React from "react";
import style from "./Footer.module.scss";
import logo from "../../assets/White-Logo.svg";
import instagram from "../../assets/instagram-white.svg";
import tikTok from "../../assets/tik tok-white.svg";
import { FOOTER_TEXT } from "../../constants";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className={style["footer-block"]}>
      <a href="/#shool" className={style.link}>
        <img src={logo} alt="logo" />
      </a>
      <NavLink
        to="/treaty"
        style={{ textDecoration: "none" }}
        className={style["footer-text"]}
      >
        <p>{FOOTER_TEXT}</p>
      </NavLink>
      <div className={style["links-block"]}>
        <a href="https://instagram.com/itgirls.by?igshid=MzRlODBiNWFlZA==">
        <img src={instagram} alt="instagram" />
        </a>
        <img src={tikTok} alt="tik tok" />
      </div>
    </div>
  );
}

export default Footer;
