import React, {useEffect} from "react";
import style from "./Teame.module.scss";
import { Titles } from "../../constants";
import Title from '../../Components/Title';
import Slider from "../../Components/Slider";
import useTeam from "../../hooks/useTeam";

function Team() {

    const {team, fetchTeam} = useTeam();

    useEffect(() => {
        fetchTeam();
    }, []);


  return (
    <div className={style["team-block"]}>
     <Title>{Titles.TITLE_OUR_TEAM}</Title>
        {team.length > 0 && <Slider team={team}/>}
    </div>
  );
}

export default Team;