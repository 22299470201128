import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    nameInputError: '',
    emailInputError: '',
    honeInputError:'',
};

export const formErrorsSlice = createSlice({
    name: 'formErrors',
    initialState,
    reducers: {
        setNameInputError: (state, action) => {
            state.nameInputError = action.payload;
        },
        setEmailInputError: (state, action) => {
            state.emailInputError = action.payload;
        },
        setPhoneInputError: (state, action) => {
            state.phoneInputError = action.payload;
        },
    }
});

export default formErrorsSlice.reducer;

export const {setNameInputError, setEmailInputError, setPhoneInputError} = formErrorsSlice.actions;