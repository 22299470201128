import React from "react";
import style from "./Button.module.scss";
import { BUTTON_SUBMIT_APPLICATION,BUTTON_ERROR } from "../../constants";

function Button({ link, onClick, black,errorButton, className }) {
  const handleButtonClick = () => {
    window.location.href = "/#form";
  };

  return (
    <button
      onClick={link ? handleButtonClick : onClick}
      className={[
        black? style['button-black'] : style['button-white'],className].join('')
      }
    >
      {
      errorButton?
      BUTTON_ERROR:
      BUTTON_SUBMIT_APPLICATION
      }
    </button>
  );
}

export default Button;
