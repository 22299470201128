import React, { useState } from "react";
import style from "./TreatyOne.module.scss";
import Chapters from "../Chapters";
import ChapterText from "../ChapterText";
import arrow from "../../../assets/arrow.svg";

function TreatyNine() {
  const [blockState, setBlockState] = useState(false);

  const onpressRow = () => {
    setBlockState(!blockState);
  };

  return (
    <div className={style["treaty"]}>
      <div className={style["treatly-swipe"]} onClick={onpressRow}> 
        <Chapters> 9. Порядок рассмотрения споров</Chapters>
        <button onClick={onpressRow} className={style["arrow-button"]}>
          <img src={arrow} alt="arrow" className={style.arrow} />
        </button>
      </div>
      <div
        className={style["scroll-block"]}
        style={blockState ? { display: "inline" } : { display: "none" }}
      >
        <div className={style["treaty-block-one"]}>
          <ChapterText>
            {" "}
            9.1. Стороны примут меры для добровольного урегулирования всех
            споров и разногласий, которые могут возникнуть в связи с исполнением
            настоящего Договора.
          </ChapterText>
          <ChapterText>
            {" "}
            9.2. В случае не достижения согласия все споры и разногласия
            подлежат рассмотрению в суде по месту нахождения ответчика в
            соответствии с законодательством Республики Беларусь.
          </ChapterText>
        </div>
      </div>
    </div>
  );
}

export default TreatyNine;
