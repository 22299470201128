import React from "react";
import style from "./Chapters.module.scss";
import traertyImage from '../../../assets/trearty-image.svg';

function Chapters({children}) {

  return (
    <div className={style['treatry-block']} >
      <img src={traertyImage} alt='treatry'/>
      <p className={style.chapters}>
      {children}
    </p>
    </div>
    
  );
}

export default Chapters;