import {createSlice} from '@reduxjs/toolkit';
import {cloneDeep} from 'lodash';

const initialState = {
    news:[]
};

export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNews: (state, action) => {
            state.news = cloneDeep(action.payload);
        }
    }
});

export default newsSlice.reducer;

export const {setNews} = newsSlice.actions;
