import React, {useRef, useEffect} from "react";
import style from "./TargetBlock.module.scss";
import flower from "../../assets/yellow-flower.png";
import quotes from "../../assets/green-quotes.png";
import lines from "../../assets/pink-lines.png";
import {FounderText} from "../../constants";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import founder from "../../assets/founder.png";
import backtag from '../../assets/backtack.png';
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

// const forderItems = FounderText.map((el) => {
//   <p key={el.id} className={style["target-language"]}>
//     {el.text}
//   </p>
// });

function TargetBlock() {
    const linesImage = useRef(null);
    const quotesImage = useRef(null);
    const flowerImage = useRef(null);
    useEffect(() => {
        const el1 = linesImage.current;
        const el2 = quotesImage.current;
        const el3 = flowerImage.current;

        gsap.fromTo(
            el1,
            {opacity: 0, scale: 0},
            {
                opacity: 1,
                scale: 1,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );
        gsap.fromTo(
            el2,
            {opacity: 0, scale: 0},
            {
                opacity: 1,
                scale: 1,
                scrollTrigger: {
                    trigger: el2,
                },
            }
        );
        gsap.fromTo(
            el3,
            {y: 0, opacity: 0},
            {
                y: 50,
                opacity: 1,
                scrollTrigger: {
                    trigger: el3,
                },
            }
        );


    }, []);
    return (
        <div className={style["target-block"]}>
            <div className={style["container-content"]}>
                <img
                    src={quotes}
                    alt="quotes"
                    className={style["quotes-image"]}
                    ref={quotesImage}
                />
                <p className={style["target-learning"]}>Почему мы?</p>
                <div className={style["content"]}>
                    <div className={style["text-content"]}>


                        {
                            FounderText.map((el) => {
                                return (
                                    <div key={el.id} className={style['founder-items']}>
                                        <img src={backtag} alt='backtag'/>
                                        <p className={style["target-language"]}
                                           dangerouslySetInnerHTML={{__html: el.text}}
                                        >
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={style["founder"]}>
                        <img src={founder} alt="founder" className={style["founder-image"]}/>
                        <img
                            src={lines}
                            alt="lines"
                            className={style["lines-image"]}
                            ref={linesImage}
                        />
                        <img
                            src={flower}
                            alt="flower"
                            className={style["flower-image"]}
                            ref={flowerImage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TargetBlock;
