import React, { useState } from "react";
import style from "./TreatyOne.module.scss";
import Chapters from "../Chapters";
import ChapterText from "../ChapterText";
import arrow from "../../../assets/arrow.svg";

function TreatyEight() {
  const [blockState, setBlockState] = useState(false);

  const onpressRow = () => {
    setBlockState(!blockState);
  };

  return (
    <div className={style["treaty"]}>
      <div className={style["treatly-swipe"]} onClick={onpressRow}> 
        <Chapters>8. Форс-мажорные обстоятельства</Chapters>
        <button onClick={onpressRow} className={style["arrow-button"]}>
          <img src={arrow} alt="arrow" className={style.arrow} />
        </button>
      </div>
      <div
        className={style["scroll-block"]}
        style={blockState ? { display: "inline" } : { display: "none" }}
      >
        <div className={style["treaty-block-one"]}>
          <ChapterText>
            {" "}
            8.1. Ни одна из сторон не будет нести ответственность за полное или
            частичное неисполнение своих обязательств, если неисполнение
            является следствием наводнения, пожара, землетрясения и других
            стихийных бедствий или военных действий, возникших после заключения
            договора. Освобождает стороны от ответственности за неисполнение или
            ненадлежащее исполнение обязательств также запрет на совершение
            действий, составляющих содержание обязательств, исходящий от
            правительства и парламента.
          </ChapterText>
          <ChapterText>
            {" "}
            8.2. Если любое из названных обстоятельств непосредственно повлияло
            на исполнение обязательства в срок, установленный в договоре, то
            этот срок соразмерно отодвигается на время действия соответствующего
            обстоятельства.
          </ChapterText>
        </div>
      </div>
    </div>
  );
}

export default TreatyEight;
