import React, { useState } from "react";
import style from "./TreatyOne.module.scss";
import Chapters from "../Chapters";
import ChapterText from "../ChapterText";
import arrow from "../../../assets/arrow.svg";

function TreatyTen() {
  const [blockState, setBlockState] = useState(false);

  const onpressRow = () => {
    setBlockState(!blockState);
  };

  return (
    <div className={style["treaty"]}>
      <div className={style["treatly-swipe"]} onClick={onpressRow}> 
        <Chapters>10. Порядок заключения настоящего договора</Chapters>
        <button onClick={onpressRow} className={style["arrow-button"]}>
          <img src={arrow} alt="arrow" className={style.arrow} />
        </button>
      </div>
      <div
        className={style["scroll-block"]}
        style={blockState ? { display: "inline" } : { display: "none" }}
      >
        <div className={style["treaty-block-one"]}>
          <ChapterText>10.1. Настоящая оферта (при акцепте – договор) составлены в одном экземпляре, имеющем юридическую силу для обеих сторон.</ChapterText>
          <ChapterText>10.2. Настоящий текст, при размещении его на сайте, является офертой на заключение публичного договора. Заключение настоящего договора с конкретным лицом – приобретение юридической силы для конкретного лица – наступает в момент оплаты (частичной оплаты) в размере, указанном в настоящем договоре и с обязательным указанием в реквизитах платежа данных плательщика, позволяющих однозначно идентифицировать его. Фамилия, имя и e-mail признаются достаточными реквизитами, однако не ограничивают плательщика в указании других реквизитов. В случае, если данные из реквизитов платежа не позволяют идентифицировать плательщика с помощью общедоступных данных (информация о паспорте не является общедоступной), или если по e-mail плательщик не выходит на связь, то ответственность за незаключение договора полностью возлагается на плательщика/Заказчика. Исполнитель оставляет за собой право вносить изменения в настоящую оферту без уведомления об этом Заказчика или любых третьих лиц помимо как размещением новой редакции на сайте.</ChapterText>
          <ChapterText>10.3. Все данные, передаваемые в ходе регистрации на данном сайте любым лицом из неограниченного круга лиц, не будут использованы Исполнителем в целях иных, чем оговорено в настоящем договоре.</ChapterText>
          <ChapterText>10.4. Лица, совершившие все действия, установленные настоящим разделом, приобретают статус Заказчика. С момента получения Исполнителем оплаты публичный договор считается заключенным в простой письменной форме (п. 3 ст. 404 ГК РБ).</ChapterText>
        </div>
      </div>
    </div>
  );
}

export default TreatyTen;
